import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-hero-tbs",
  template: `
    <div class="image-background">
      <div class="container-tbs">
        <div class="title">
          <h1>Total Body Scan</h1>
          <h4>Your inside <span>selfie</span></h4>
          <p>
            Un estudio completo, no invasivo y con la tecnología más avanzada,
            para tu tranquilidad y bienestar.
          </p>
          <button class="button-animated">
            <a target="_self" href="https://square.link/u/vr769yh2"
              >Comprar tu estudio ahora</a
            >
          </button>

          <div class="service-location">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              viewBox="0 0 23 23"
              fill="none"
            >
              <path
                d="M11.5 2.15625C7.92871 2.15625 5.03125 4.91401 5.03125 8.31055C5.03125 12.2188 9.34375 18.4121 10.9272 20.5513C10.993 20.6416 11.0791 20.7151 11.1787 20.7658C11.2782 20.8164 11.3883 20.8428 11.5 20.8428C11.6117 20.8428 11.7218 20.8164 11.8213 20.7658C11.9209 20.7151 12.007 20.6416 12.0728 20.5513C13.6562 18.413 17.9688 12.2219 17.9688 8.31055C17.9688 4.91401 15.0713 2.15625 11.5 2.15625Z"
                stroke="#828282"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.5 10.7812C12.6909 10.7812 13.6562 9.81586 13.6562 8.625C13.6562 7.43414 12.6909 6.46875 11.5 6.46875C10.3091 6.46875 9.34375 7.43414 9.34375 8.625C9.34375 9.81586 10.3091 10.7812 11.5 10.7812Z"
                stroke="#828282"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Miami
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ["./hero.component.scss"],
})
export class HeroComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
