import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Contrato} from '../models/contrato.model';
import {Product} from '@models';
import {environment} from '../../environments/environment';
import {LangService} from './lang.service';

@Injectable({
  providedIn: 'root'
})
export class ContractService {

  constructor(private httpClient: HttpClient, private langService: LangService) {
  }

  public getAll$(): Observable<Contrato[]> {
    let params = new HttpParams();
    params = params.append('lang', this.langService.getLang());

    return this.httpClient.get<Contrato[]>(
      environment.apiBase + '/tiendas/' + environment.idStore + '/contratos/usuario',
      {params}
    );
  }

  public getOne$(contratoId: number): Observable<Contrato> {
    return this.httpClient.get<Contrato>(
      environment.apiBase + '/tiendas/' + environment.idStore + '/contratos/' + contratoId + '/usuario'
    );
  }

  invitarAdmin(id: number, nombre: any, email: any, apellido: string): Observable<any> {
    const invitation = {nombre, apellido, email};
    return this.httpClient.post(
      environment.apiBase + '/tiendas/' + environment.idStore + '/contratos/' + id + '/invitar', invitation
    );
  }
}
