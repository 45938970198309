<h2 mat-dialog-title class="mat-typography__underscore" i18n="SchedulableResourcePopUpTitle | Titulo PopUp Recursos Agendables">Schedulable Resources</h2>

<div *ngIf="loading" style="height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;">
  <mat-spinner></mat-spinner>
</div>

<mat-dialog-content *ngIf="!loading">
  <!--  <div class="container">-->
  <!--    <form>-->
  <!--            <input [min]="minDate" matInput [matDatepicker]="picker" placeholder="From" i18n-placeholder="PlaceholderFromDatePayments" [(ngModel)]="fechaDesde" (dateChange)="obtenerMenuFechas($event.value.toDate())">-->
  <!--      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>-->
  <!--      <mat-datepicker #picker></mat-datepicker>-->
  <!--    </form>-->
  <!--  </div>-->
  <div class="container" style="justify-content: center; align-items: baseline;" id="divFechas">
    <mat-form-field style="width:1px;visibility:hidden;">
      <input [min]="minDate" matInput [matDatepicker]="picker" placeholder="From" i18n-placeholder="PlaceholderFromDatePayments" [(ngModel)]="fechaDesde" (dateChange)="obtenerMenuFechas($event.value.toDate())">
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <button mat-button (click)="picker.open()">
      <span i18n="SelectAnotherDateButton | Horarios Disponibles Label PopUp Recursos Agendables">Select another date </span>
      <mat-icon>calendar_today</mat-icon>
    </button>
    <ng-container *ngFor="let fecha of this.arrayFechas, let i = index">
      <button id="bt{{i}}" style="margin: 8px; padding: 0" mat-flat-button color="primary" (click)="obtenerRecursosConAgendas(fecha)">
        <h4 style="margin: 16px">{{fecha | date:'shortDate'}}</h4>
      </button>
    </ng-container>
  </div>

  <!-- begin Payments Table -->
  <div>
    <p i18n="AvailableSchedulesSchedResPopup | Horarios Disponibles Label PopUp Recursos Agendables">Available schedules on: {{fechaDesde | date:'shortDate'}}</p>
  </div>
  <table mat-table [dataSource]="dataSource" matSort matSortActive="date" class="mat-table--numbers mat-elevation-0">

    <ng-container matColumnDef="inicio">
      <th mat-header-cell *matHeaderCellDef mat-sort-header><span i18n="FromDateSchedResPopup | Columna Inicio PopUp Recursos Agendables">Start</span></th>
      <td mat-cell *matCellDef="let element"> {{element.fechaInicio | date:'HH:mm'}} </td>
    </ng-container>

    <ng-container matColumnDef="fin">
      <th mat-header-cell *matHeaderCellDef mat-sort-header><span i18n="ToDateSchedResPopup | Columna Fin PopUp Recursos Agendables">End</span></th>
      <td mat-cell *matCellDef="let element"> {{element.fechaFin  | date:'HH:mm'}} </td>
    </ng-container>

    <ng-container matColumnDef="nombre">
      <th mat-header-cell *matHeaderCellDef mat-sort-header><span i18n="NameSchedResPopup | Columna Nombre PopUp Recursos Agendables">Name</span></th>
      <td mat-cell *matCellDef="let element"> {{element.recurso.nombre}} </td>
    </ng-container>

    <ng-container matColumnDef="precio">
      <th mat-header-cell *matHeaderCellDef mat-sort-header><span i18n="PriceSchedResPopup | Columna Precio PopUp Recursos Agendables">Price</span></th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngFor="let precio of element.precios">
          <p *ngIf="precio.tipo == 'UNITARIO'">
            <ng-container *ngIf="precio.precio > 0">
              <span>{{precio?.moneda.simbolo}}</span>{{precio.precio | number:'0.2-2'}}
            </ng-container>
          </p>
        </ng-container>
      </td>
    </ng-container>


    <ng-container matColumnDef="acciones">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let element">
        <button (click)="agendar(element)" mat-flat-button color="primary" i18n="AgendarSelectionButton | Boton seleccionar popUp agendables">Select</button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="displayedColumns.length" i18n="SchedulableResNotFound | Texto sin resultados popUp Agendables">
        There are no results, select another date.
      </td>
    </tr>
  </table>
  <mat-paginator [length]="total" (page)="paginar($event)" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>

</mat-dialog-content>

