import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { CategoryService, ProductService } from "@services";
import { filter, switchMap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Category, Product } from "@models";
import { ActivatedRoute, Router } from "@angular/router";
import { SearchBoxService } from "../../services/search-box.service";
import { environment } from "../../../environments/environment";
import { LoginService } from "../../components/login-service";
import { Plan } from "src/app/models/plan.model";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ContactFormModal } from "./contact-form/contact-form.component";

@Component({
  selector: "app-product-page",
  templateUrl: "./product-page.html",
  styleUrls: ["./product-page.component.scss"],
})
export class ProductPage implements OnInit {
  public breadscrum: [Observable<Category>];
  public product: Product;
  public user;
  selectedTab = 0;
  sumAllProductsPrice = 0;
  disableInterest = false;
  wpp: string;

  constructor(
    private titleService: Title,
    private categoryService: CategoryService,
    private route: ActivatedRoute,
    private router: Router,
    private productService: ProductService,
    private searchBoxService: SearchBoxService,
    private loginService: LoginService,
    private modal: NgbModal
  ) {
    // @ts-ignore
    this.breadscrum = [];

    this.loginService.user$.subscribe((u) => {
      this.user = u;
    });
    if (environment.phoneNumber) {
      this.wpp = environment.phoneNumber;
    }
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params["tab"] !== undefined) {
        this.selectedTab = params["tab"];
      }
    });

    let auxBreadscrum = this.router.url.split("/");
    //console.log(auxBreadscrum);

    //ESTE ARREGLO ES POR LA LANDING DEL TOTAL BODY SCAN y PHARMCARE CARD

    if (
      auxBreadscrum.includes("total-body-scan") ||
      auxBreadscrum.includes("fullBodyScan")
    ) {
      this.router.navigateByUrl("total-body-scan/landing");
      return;
    }
    if (auxBreadscrum.includes("pharmcare-digital-card")) {
      this.router.navigateByUrl("pharmacare-card/landing");
      return;
    }

    auxBreadscrum = auxBreadscrum.slice(1, auxBreadscrum.length - 2);
    auxBreadscrum.forEach((idExterno) => {
      this.breadscrum.push(this.categoryService.getOneDeepSearch$(idExterno));
    });
    this.route.params
      .pipe(
        filter((data) => data.product),
        switchMap((data) =>
          this.productService.getProductByIdExterno$(data.product)
        )
      )
      .subscribe((product: Product) => {
        this.sumAllProductsPrice = 0;
        this.product = product;
        product.planes.forEach((plan) => {
          plan.hideBuy = true;
          plan.precios
            .filter((precio) => precio.tipo === "MENSUAL")
            .forEach((precio) => {
              this.sumAllProductsPrice += precio.precio;
              plan.hideBuy = precio.precio > 0 ? false : true;
            });
        });
        this.titleService.setTitle(environment.title + " " + product.nombre);
      });
  }

  moveToSelectedTab(tabId: string) {
    for (
      let i = 0;
      i < document.querySelectorAll(".mat-tab-label-content").length;
      i++
    ) {
      const element = document.querySelectorAll(
        ".mat-tab-label-content .navegable"
      )[i];
      if (element && element.id === tabId) {
        element.parentElement.click();
      }
    }
  }

  getPlanesOrdenados(): Plan[] {
    var planes = this.product.planes;

    planes.sort((a, b) => a.ordenDespliegue - b.ordenDespliegue);
    return planes;
  }

  doLogin() {
    this.loginService.login();
  }

  doInterested() {
    this.disableInterest = true;
    this.productService
      .imInterested$(this.product.idExterno)
      .subscribe(() => {});
  }

  showContactModal() {
    const modalRef = this.modal.open(ContactFormModal, {});
    modalRef.componentInstance.formTipo = this.product.nombre;
  }
}
