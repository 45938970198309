import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BannerService {

  constructor(private httpClient: HttpClient) {
  }

  getBanner(tiendaId: number, categoriaId: number, lang: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('lenguaje', lang);
    return this.httpClient.get<any>(
      environment.apiBase + '/tiendas/' + tiendaId + '/categoria/' + categoriaId + '/banners', {params}
      // 'http://localhost:8080/suscripciones-backend/tiendas/'+ tiendaId +'/categoria/' + categoriaId + '/banners' , {params}
    );
  }
}
