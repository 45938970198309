import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-wpp-btn",
  templateUrl: "./wpp-btn.component.html",
  styleUrls: ["./wpp-btn.component.scss"],
})
export class WppBtnComponent implements OnInit {
  color: string = "#25D366";
  wpp: string;

  constructor() {
    if (environment.phoneNumber) {
      this.wpp = environment.phoneNumber;
    }
  }

  ngOnInit(): void {}
}
