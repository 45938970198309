<!--<div class="container">-->
<!--  <div class="row">-->
<!--    <div class="col-12">-->
<!-- <div class="nav_types_app"> -->
<!-- <a #button mat-button class="btn-outline f-light has_menu" [matMenuTriggerFor]="menuCategorys" #matMenu1Trigger="matMenuTrigger"  i18n="CategoriesButton | Boton de menu de categorias">
          <i class="material-icons">menu</i>
          Categories
        </a> -->

  <a class="categories-cta" [matMenuTriggerFor]="menuCategorys" #matMenu1Trigger="matMenuTrigger"
    i18n="CategoriesButton | Boton de menu de categorias">
    <mat-icon>menu</mat-icon>
    Categories
  </a>

  <!--        Menú para utilizar cuando hayan subcategorías-->

  <!--        <mat-menu #menuCategorys="matMenu" yPosition="below" class="menuCategorys" backdropClass="backdropMenuCategorys">-->
  <!--          <p class="textRed f-light" fxShow="true" fxHide.gt-sm>Categories</p>-->
  <!--          <span (click)="matMenu1Trigger.closeMenu()" class="closeMenu"></span>-->
  <!--          <div class="navApps"*ngFor="let category of categoriesAux">-->
  <!--            <p class="navTitle"><a [routerLink]="'/'+category.fullIdExterno">{{category.nombre}}</a></p>-->
  <!--            <a href="" *ngFor="let subcategory of category.categorias" [routerLink]="'/'+subcategory.fullIdExterno">{{subcategory.nombre}}</a>-->
  <!--          </div>-->
  <!--        </mat-menu>-->


  <mat-menu #menuCategorys="matMenu" yPosition="below" class="menuCategorys mat-menu-categories"
    backdropClass="backdropMenuCategorys">
    <!-- <button mat-icon-button aria-label="Close menu" (click)="matMenu1Trigger.closeMenu()" class="mat-menu-close">
            <mat-icon>close</mat-icon>
          </button> -->
    <!-- <span class="menu-categories-title" i18n="FeaturedCategories | Categorias Destacadas titulo ">Featured Categories</span> -->

    <div class="menu-categories-list">
      <ng-container *ngFor="let category of categories">
        <ng-container *ngIf="!category.ocultar">
          <a *ngIf="!category.ocultar" [routerLink]="'/'+category.fullIdExterno">{{category.nombre}}</a>
          <ng-container *ngFor="let subcategoria of category.categorias">
            <a class="subcategoria" *ngIf="!subcategoria.ocultar"
              [routerLink]="'/'+subcategoria.fullIdExterno">{{subcategoria.nombre}}</a>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </mat-menu>


  <!--        <a *ngFor="let category of categories" [routerLink]="'/'+category.fullIdExterno" mat-button-->
  <!--           class="btn-outline f-light f-light">-->
  <!--          {{category.nombre}}-->
  <!--        </a>-->
  <!-- </div> -->
  <!--    </div>-->
  <!--  </div>-->
  <!--</div>-->