import { NgModule } from "@angular/core";
import { FooterComponent } from "./tbs/footer/footer.component";
import { CommonModule } from "@angular/common";
import { TotalBodyScanLandingPage } from "./tbs/total-body-scan.landing";
import { LandingHeaderComponent } from "./ui/header/header.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HeroComponent } from "./tbs/hero/hero.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { ContactUsFormComponent } from "./ui/form/contact-us-form.component";
import { PharmacareCardLandingPage } from "./pharmacare/pharmacare.landing";
import { PharmacareLandingHeaderComponent } from "./pharmacare/header/header.component";
import { PharmacareLandingHeroComponent } from "./pharmacare/hero/hero.component";
import { PharmacareLandingFirstSection } from "./pharmacare/sections/first-section/first-section.component";
import { PharmacareLandingContactUsFormComponent } from "./pharmacare/sections/form-section/form/contact-us-form.component";
import { PharmacareLandingFormSection } from "./pharmacare/sections/form-section/form-section.component";
import { PharmacareLandingThirdSection } from "./pharmacare/sections/third-section/third-section.component";
import { PharmacareLandingFooterComponent } from "./pharmacare/footer/footer.component";
import { PharmacareLandingFourthSection } from "./pharmacare/sections/fourth-section/fourth-section.component";

@NgModule({
  declarations: [
    FooterComponent,
    TotalBodyScanLandingPage,
    LandingHeaderComponent,
    HeroComponent,
    ContactUsFormComponent,
    PharmacareCardLandingPage,
    PharmacareLandingHeaderComponent,
    PharmacareLandingHeroComponent,
    PharmacareLandingFirstSection,
    PharmacareLandingContactUsFormComponent,
    PharmacareLandingFormSection,
    PharmacareLandingThirdSection,
    PharmacareLandingFooterComponent,
    PharmacareLandingFourthSection
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  exports: [TotalBodyScanLandingPage, PharmacareCardLandingPage, ContactUsFormComponent],
})
export class LandingsModule {}
