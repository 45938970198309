<div class="breadcrumb">
  <!-- appCats -->
  <div class="container">
    <a routerLink="/" i18n="Home">Home</a>
    <span> / </span> <span><a routerLink="/blog">Blog</a></span>
    <span> / </span><span><a routerLink="/blog/events">{{ isEnglish ? 'Events' : 'Eventos' }}</a></span>
    <span> / </span> <span>{{ isEnglish ? elemento.copete : elemento.nombre }}</span>
  </div>
</div>

<!-- Eventos 
    nombre = Titulo español
    copete = Titulo ingles
    descripcionHTML = Descripcion español
    descripcion = Descripcion ingles
    fuente = Ubicacion
    fechaElementoInicio = Visible desde
    fechaElementoFin = Visible hasta 
    estadoElemento = Estado
    -->

<div class="container" *ngIf="elemento">
<!-- Carrusel para más de una imagen -->
<ngb-carousel *ngIf="
    (elemento.detailImage ||
      elemento.detailImage2 ||
      elemento.detailImage3) &&
    (elemento.detailImage2 || elemento.detailImage3)
  " interval="9000" pauseOnHover="true" [showNavigationArrows]="true" class="carousel">
  <ng-template ngbSlide *ngIf="elemento.detailImage">
    <div class="carousel-item">
      <img class="carousel-image" [src]="elemento.detailImage" alt="{{ isEnglish ? elemento.copete : elemento.nombre }}" />
    </div>
  </ng-template>

  <ng-template ngbSlide *ngIf="elemento.detailImage2">
    <div class="carousel-item">
      <img class="carousel-image" [src]="elemento.detailImage2" alt="{{ isEnglish ? elemento.copete : elemento.nombre }}" />
    </div>
  </ng-template>

  <ng-template ngbSlide *ngIf="elemento.detailImage3">
    <div class="carousel-item">
      <img class="carousel-image" [src]="elemento.detailImage3" alt="{{ isEnglish ? elemento.copete : elemento.nombre }}" />
    </div>
  </ng-template>
</ngb-carousel>

<!-- Imagen única, sin carrusel -->
<div *ngIf="
    elemento.detailImage && !elemento.detailImage2 && !elemento.detailImage3
  " class="single-image-container">
  <img class="single-image" [src]="elemento.detailImage" alt="{{ isEnglish ? elemento.copete : elemento.nombre }}" />
</div>

  <div class="event-info">
    <div class="event-header">
      <h1 class="event-title">{{ isEnglish ? elemento.copete : elemento.nombre }}</h1>
      <div class="aditional-info">
        <p class="event-date">
          {{ elemento.fechaInfoInicio | date: 'dd MMMM yyyy' }}
          <span *ngIf="elemento.fechaInfoFin"> - {{ elemento.fechaInfoFin | date: 'dd MMMM yyyy' }}</span>
        </p>
        
        <p class="event-location">{{ elemento.fuente }}</p>
      </div>
    </div>

    <div class="event-description">
      <p [innerHtml]="sanitizeHtml(isEnglish ? elemento.descripcion : elemento.descripcionHtml)"></p>
    </div>
  </div>
</div>