<h2 mat-dialog-title>{{notification.titulo}}</h2>
<div mat-dialog-content class="mat-typography">
  <h3>{{notification.copete}}</h3>
  <p>{{notification.mensajeHtml}}</p>
  <ng-template [ngIf]="notification.accionLink!=null">
    <a href="{{notification.accionLink}}">{{notification.accionTitulo}}</a>
  </ng-template>
</div>
<mat-dialog-actions align="center">
  <button mat-stroked-button color="accent" mat-dialog-close cdkFocusInitial (click)="dialogRef.close()"i18n="matDialogCerrrar | Cerrar ">Close</button>
</mat-dialog-actions>
