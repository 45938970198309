import { Component, Input } from "@angular/core";

@Component({
  selector: "app-pharmacare-landing-form-section",
  template: `
    <section id="form-section" class="container-pharma">
      <div class="container-tbs">
        <div>
          <h2><span>¡Adquiere</span> tu tarjeta digital!</h2>
          <p>Viaja tranquilo a Estados Unidos. Recibirás tu 
          <span>tarjeta digital</span> de forma instantánea en tu celular, una vez confirmada la compra.
          </p>
          <div class="ctas">
            <button class="button-animated">
              <a target="_self" [href]="buyLink"
                >Comprar</a
              >
            </button>
            <button class="button-animated">
              <a
                target="_self"
                href="https://api.whatsapp.com/send?phone=59899389189&type=phone_number&app_absent=0"
                >Whatsapp</a
              >
            </button>
          </div>
        </div>
        <app-pharmacare-landing-contactus-form
          formTipo="landing-pharmacare"
        ></app-pharmacare-landing-contactus-form>
      </div>
    </section>
  `,
  styleUrls: ["./form-section.component.scss"],
})
export class PharmacareLandingFormSection {
  @Input() buyLink: string;
}
