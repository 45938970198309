<div class="container">
  <div class="row">
    <div class="col-12 d-none d-sm-block">
      <img src="assets/temp/provider.png" alt="">
    </div>
    <div class="col-12 d-block d-sm-none">
      <img src="assets/temp/provider-movil.jpg" alt="">
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-12 py-4">
      <img src="assets/temp/become.png">
    </div>
    <div class="col-xs-12 col-md-6 pb-5">
      <p>
        We are constantly seeking to partner with professionals and providers who bring quality services. If you are
        interested in listing yours on the platform, please fill out the form.
      </p>
    </div>


    <div class="col-xs-12 col-md-6 d-flex justify-content-center">
      <p *ngIf="submited">
        We will be contacting you soon...
      </p>
      <form *ngIf="!submited" [formGroup]="contactForm" class="col-md-8 col-12">
        <div class="form-group">
          <label for="first-name">First Name *</label>
          <input [ngClass]="contactForm.get('nombre').invalid && (contactForm.get('nombre').dirty || contactForm.get('nombre').touched) ?'is-invalid':''" formControlName="nombre" type="text" class="form-control" id="first-name">
        </div>
        <div class="form-group">
          <label for="last-name">Last Name *</label>
          <input [ngClass]="contactForm.get('apellido').invalid && (contactForm.get('apellido').dirty || contactForm.get('apellido').touched) ?'is-invalid':''" formControlName="apellido" type="text" class="form-control" id="last-name">
        </div>
        <div class="form-group">
          <label for="email">Email *</label>
          <input [ngClass]="contactForm.get('email').invalid && (contactForm.get('email').dirty || contactForm.get('email').touched) ?'is-invalid':''" formControlName="email" type="email" class="form-control" id="email">
        </div>
        <div class="form-group">
          <div class="form-check">
            <input [ngClass]="contactForm.get('acceptTerms').invalid && (contactForm.get('acceptTerms').dirty || contactForm.get('acceptTerms').touched) ?'is-invalid':''" formControlName="acceptTerms" class="form-check-input" type="checkbox" id="acept">
            <label class="form-check-label" for="acept">
              Sending this form you are accepting our Terms & Conditions.
            </label>
          </div>
        </div>
        <div class="form-group">
          <div class="col-sm-10">
            <button [disabled]="loading" type="button" (click)="sendContact()" class="btn btn-primary">
              <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
