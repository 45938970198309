import { Component, ElementRef, Inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductService } from '@services';
import { Pais, Product } from '@models';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, switchMap } from 'rxjs/operators';
import { OrderService } from '../../services/order.service';
import { Plan } from '../../models/plan.model';
import { MatStepper } from '@angular/material/stepper';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { BehaviorSubject, Observable } from 'rxjs';
import { Client } from '../../models/client.model';
import { ContactService } from '../../services/contact.service';
import { ClienteContactoService } from '../../services/cliente-contacto.service';
import { OrdenPlanCompraRecurso } from '../../models/ordenplancomprarecurso.model';
import { ComplementsListComponent } from '../../components/complements-list/complements-list.component';
import { PaisService } from '../../services/pais.service';
import { LoginService } from '../../components/login-service';
import { AppraisalService } from '../../services/appraisal.service';
import { environment } from '../../../environments/environment';
import { PaymentService } from '../../services/payment.service';
import { Appraisal } from '../../models/appraisal.model';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from '../../services/notification.service';
import { Notification } from '../../models/notification.model';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { PlatformLocation } from '@angular/common';
import { mustBeTrueValidator } from 'src/app/validators/must-be-ture.validator';


@Component({
  selector: 'app-purchase-page',
  templateUrl: './purchase-page.html',
  styleUrls: [],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }]
})
export class PurchasePage implements OnInit {
  planFormGroup: FormGroup;
  clienteFormGroup: FormGroup;
  contactoPersonaFormGroup: FormGroup;
  contactoEmpresaFormGroup: FormGroup;
  cuponForm: FormGroup;
  paymentFormGroup: FormGroup;
  recursos: OrdenPlanCompraRecurso[];
  product: Product;
  appraisal: Appraisal;
  total: number;
  importeGravado: number;
  cliente: Client;
  cupon: string;
  busy = false;
  esPersona = false;
  esEmpresa = false;
  paises$: Observable<Pais[]>;
  planId = null;
  triggerErrorControls = false;
  orden: { id: number, idExterno: string };
  lastPricedPlan: Plan;
  plan: Plan;
  plan$: BehaviorSubject<Plan>;
  pathValueResouces$: BehaviorSubject<OrdenPlanCompraRecurso[]>;
  firstTime = true;
  public config: MatSnackBarConfig;
  termsForm: FormGroup;
  orderPlaced: boolean;


  @ViewChild('stepper') private wizzard: MatStepper;
  @ViewChildren(ComplementsListComponent) query: QueryList<ComplementsListComponent>;

  @ViewChild('hiddenForm')
  hiddenFormReference: ElementRef;
  hiddenFormGroup: FormGroup;


  @ViewChild(ComplementsListComponent) private complementosForm: ComplementsListComponent;


  clientesContactos = [];
  clienteContacto;
  busyAppraisalService = false;
  paymentGatewayData;
  editable = true;
  OnetimeCost = false;
  lang = 'es';

  constructor(private fb: FormBuilder,
    private productService: ProductService,
    private route: ActivatedRoute,
    private orderService: OrderService,
    private loginService: LoginService,
    private notificactionService: NotificationService,
    private contactService: ContactService,
    private clienteContactoService: ClienteContactoService,
    private paisesService: PaisService,
    private appraisalService: AppraisalService,
    private paymentService: PaymentService,
    private router: Router,
    public snackbar: MatSnackBar,
    public dialog: MatDialog,
    private platformLocation: PlatformLocation) {

    this.loginService.user$.subscribe(value => {
      if (value != null) {
        this.notificactionService.getAll$(10, 0, value.email).subscribe(value => {
          // @ts-ignore
          const notificaciones: Notification[] = value.notificaciones;
          for (const notification of notificaciones) {
            if (notification.titulo === 'Orden en Proceso' && notification.tipoId === 'cached') {
              this.OpenDialogOrder(notification);
              break;
            }
          }
        });
      }
    });

    this.termsForm = this.fb.group({});
    this.orderPlaced = false;

  }


  ngOnInit(): void {
    const url = this.platformLocation.href;
    const base = environment.urlApp;
    let lang = url.substr(base.length, 2);
    //console.log('base', base);
    //console.log('lang' + lang);
    if (lang != 'es') {
      this.lang = 'en';
    }

    this.buildFormGroups();
    this.plan$ = new BehaviorSubject<Plan>(null);
    this.pathValueResouces$ = new BehaviorSubject<OrdenPlanCompraRecurso[]>(null);

    this.paises$ = this.paisesService.getAll$();
    this.clienteContactoService.getAll$().subscribe(x => {
      this.clientesContactos = x;
    });
    this.route.params.pipe(
      filter(data => data.planId),
    ).subscribe((data) => {
      this.planId = data.planId;
    });
    this.route.params.pipe(
      filter(data => data.product),
      switchMap(data => this.productService.getProductByIdExterno$(data.product))
    ).subscribe((product: Product) => {
      this.product = product;
      let groupObj = {};
      if (this.product?.infoTermsHtml?.trim()) this.termsForm.addControl("infoTermsAgreement", this.fb.control(false, mustBeTrueValidator()));
      if (this.product?.infoTermsTiendaHtml?.trim()) this.termsForm.addControl("infoTermsTiendaAgreement", this.fb.control(false, mustBeTrueValidator()));

      const url: string = this.router.url.lastIndexOf('?') === -1 ? this.router.url : this.router.url.substring(0, this.router.url.lastIndexOf('?'));
      // Modificar para que no tome el signo de preguntas si mandan un parametro por query
      const idExternoPlan: string = url.substring(this.router.url.lastIndexOf('/') + 1);
      const auxPlan: Plan = this.product.planes.find(plan => plan.idExterno === idExternoPlan);
      this.planFormGroup.controls['plan'].patchValue(auxPlan.id.toString());

    });
    this.planFormGroup.valueChanges.subscribe(value => {
      this.plan = this.product.planes.find(plan => plan.id === Number(value.plan));
      this.plan$.next(this.plan);
      if (this.firstTime) {
        this.route.queryParams.subscribe(params => {
          if (params['orderId'] !== undefined) {
            this.orderService.getOneExternalId(params['orderId']).subscribe(order => {
              this.pathValueResouces$.next(order.recursos);
              this.router.navigate([], { queryParams: {} });
            });
          }
        });
        this.firstTime = false;
      } else {
      }
      this.calculatePrice2();
    });
  }

  public compare(a, b) {
    return a && b && Number(a) === Number(b);
  }

  crearOrdenCompra() {
    this.busy = true;
    this.editable = false;
    this.orderService.createOrderPlan(this.lastPricedPlan, this.cuponForm.value.cupon, this.clienteContacto.cliente, this.clienteContacto.contacto, this.recursos).subscribe(result => {
      this.busy = false;
      this.wizzard.selected.completed = true;
      this.wizzard.next();
      this.paymentGatewayData = result;
    });
  }

  private termsAccepted(): boolean {
    return this.termsForm.valid;
  }

  crearOrdenCompraPagoAnticipado() {

    this.orderPlaced = true;
    if (!this.termsAccepted()) {
      // this.snackbar.open("Tienes que aceptar los términos y condiciones del servicio.", "Cerrar", {
        // duration: 3000
      // });
      return;
    }

    this.busy = true;
    this.editable = false;
    this.orderService.createOrderPlanPagoAnticipado(this.lastPricedPlan, this.cuponForm.value.cupon, this.clienteContacto.cliente, this.clienteContacto.contacto, this.recursos)
      .subscribe(response => {
        this.busy = false;
        if (response != null && response !== undefined && response !== '') {
          if (response === 'EXITO') {
            this.wizzard.selected.completed = true;
            this.wizzard.next();
          } else {
            document.open('text/html');
            document.write(response);
            document.close();
          }
        }
      },
        error => {
          this.busy = false;
          this.config = new MatSnackBarConfig();
          this.config.duration = 5000;
          const mensaje = 'Hubo un error al crear la orden, Intente mas tarde';
          this.snackbar.open(mensaje, 'Cerrar', this.config);
        }
      );
  }

  public equals(a, b) {
    return (a && b) && (a === b);
  }

  crearModificarClienteYContacto() {
    // console.log("Estado del form:", this.clienteFormGroup.valid);
    // console.log("Errores del form:", this.clienteFormGroup.errors);
    this.markFormGroupTouched(this.clienteFormGroup);
    this.markFormGroupTouched(this.contactoPersonaFormGroup);
    this.markFormGroupTouched(this.contactoEmpresaFormGroup);

    if (this.clienteFormGroup.valid && ((this.esPersona && this.contactoPersonaFormGroup.valid) || (this.esEmpresa && this.contactoEmpresaFormGroup.valid))) {
      const clienteContacto: any = {};
      clienteContacto['cliente'] = this.clienteFormGroup.value;
      clienteContacto['cliente'].tiendaId = environment.idStore;
      if (this.esPersona) {
        clienteContacto['contacto'] = this.contactoPersonaFormGroup.value;
        clienteContacto['contacto'].esEmpresa = false;
        clienteContacto['contacto'].esPersona = true;
      }
      if (this.esEmpresa) {
        clienteContacto['contacto'] = this.contactoEmpresaFormGroup.value;
        clienteContacto['contacto'].esEmpresa = true;
        clienteContacto['contacto'].esPersona = false;
      }
      if (this.clienteContacto.cliente.id) {
        if ((this.esPersona && this.contactoPersonaFormGroup.dirty) || (this.esEmpresa && this.contactoEmpresaFormGroup.dirty)) {
          this.clienteContactoService.update(this.clienteContacto.cliente.id, clienteContacto).subscribe((result: any) => {
            this.clientesContactos = this.clientesContactos.filter(cc => cc.cliente.id !== this.clienteContacto.cliente.id);
            this.clienteContacto = { contacto: { ...this.clienteContacto.contacto, ...clienteContacto.contacto, id: result.contacto.id }, cliente: { ...this.clienteContacto.cliente, ...clienteContacto.cliente } };
            this.clientesContactos.push(this.clienteContacto);
            this.contactoPersonaFormGroup.markAsPristine();
            this.contactoEmpresaFormGroup.markAsPristine();
          });
        }
      } else {
        this.clienteContactoService.create(clienteContacto).subscribe(result => {
          this.clienteContacto = result;
          this.clientesContactos.push(result);
        });
      }
      this.triggerErrorControls = false;
      this.wizzard.selected.completed = true;
      this.wizzard.next();
    } else {
      this.triggerErrorControls = true;
    }
    this.calculatePrice();
  }

  public markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control: any) => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  cargarClienteContacto(clientContacto: any) {
    this.clienteContacto = {};
    this.clienteContacto.cliente = clientContacto.cliente;
    if (clientContacto.contacto.esPersona) {
      this.contactoPersonaFormGroup.reset();
      this.clienteFormGroup.reset();
      this.esEmpresa = false;
      this.esPersona = true;
      this.clienteContacto.contacto = clientContacto.contacto;
      this.contactoPersonaFormGroup.patchValue(this.clienteContacto.contacto);
    }
    if (clientContacto.contacto.esEmpresa) {
      this.contactoPersonaFormGroup.reset();
      this.clienteFormGroup.reset();
      this.esEmpresa = true;
      this.esPersona = false;
      this.clienteContacto.contacto = clientContacto.contacto;
      this.contactoEmpresaFormGroup.patchValue(this.clienteContacto.contacto);
    }
    this.clienteFormGroup.patchValue(this.clienteContacto.cliente);
    this.wizzard.selected.completed = true;
    this.wizzard.next();
  }

  nuevoClienteContactoPersona() {
    this.contactoPersonaFormGroup.reset();
    this.clienteFormGroup.reset();
    this.contactoPersonaFormGroup.patchValue(
      {
        nombre: this.loginService.getLoggedUser().firstName,
        apellido: this.loginService.getLoggedUser().lastName,
        email: this.loginService.getLoggedUser().email
      }
    );
    this.esEmpresa = false;
    this.esPersona = true;
    this.clienteContacto = { cliente: {}, contacto: {} };

    this.clienteFormGroup.patchValue(this.clienteContacto);
    this.wizzard.selected.completed = true;
    this.wizzard.next();
  }

  nuevoClienteContactoEmpresa() {
    this.contactoEmpresaFormGroup.reset();
    this.clienteFormGroup.reset();
    this.contactoEmpresaFormGroup.patchValue(
      {
        paisDomicilio: { id: 1 },
        nombre: this.loginService.getLoggedUser().firstName,
        apellido: this.loginService.getLoggedUser().lastName,
        email: this.loginService.getLoggedUser().email
      }
    );
    this.esEmpresa = true;
    this.esPersona = false;
    this.clienteContacto = { cliente: {}, contacto: {} };

    this.clienteFormGroup.patchValue(this.clienteContacto);
    this.wizzard.selected.completed = true;
    this.wizzard.next();
  }

  validatePlanResourceAndNext() {
    //console.log('Validando planRecursos');
    const planAux: Plan = this.product.planes.find(plan => plan.id === Number(this.planFormGroup.value.plan));
    if (this.planFormGroup.valid && this.complementosForm !== undefined) {
      if (this.complementosForm.isValid()) {
        if (this.lastPricedPlan !== planAux || (this.recursos !== this.complementosForm.recursos && (this.recursos.length > 0 || this.complementosForm.recursos.length > 0))) {
          // this.OpenDialogPrice();
        } else {
          this.wizzard.selected.completed = true;
          this.wizzard.next();
        }
      } else {
        this.complementosForm.showErrors = true;
      }
    } else if (this.planFormGroup.valid) {
      if (this.lastPricedPlan !== planAux) {
        // this.OpenDialogPrice();
      } else {
        this.wizzard.selected.completed = true;
        this.wizzard.next();
      }
    }
  }

  OpenDialogOrder(value: Notification) {
    const dialogRef = this.dialog.open(OrderMessageDialog, {
      width: '600px',
      data: value,
      disableClose: true
    });
  }

  goToBack() {
    this.wizzard.previous();
    this.wizzard.selected.completed = false;
  }

  buildFormGroups(): void {
    this.clienteFormGroup = this.fb.group({
      nombre: [null, Validators.required],
    });
    this.paymentFormGroup = this.fb.group({});
    this.contactoPersonaFormGroup = this.fb.group(
      {
        nombre: [null, Validators.required],
        apellido: [null, Validators.required],
        tipoDocumento: [null, Validators.nullValidator],
        numeroDocumento: [null, Validators.nullValidator],
        paisDocumento: [null, Validators.nullValidator],
        paisDomicilio: [null, Validators.nullValidator],
        ciudad: [null, Validators.nullValidator],
        telefono: [null, Validators.required],
        domicilio: [null, Validators.nullValidator],
        codigoPostal: [null, [Validators.nullValidator]],
        email: [null, Validators.required],
        departamento: [null, Validators.nullValidator],
      });

    this.contactoEmpresaFormGroup = this.fb.group({
      rut: [null, Validators.required],
      email: [null, Validators.required],
      razonSocial: [null, Validators.required],
      nombreComercial: [null, Validators.required],
      ciudad: [null, Validators.required],
      telefono: [null, Validators.required],
      domicilio: [null, Validators.nullValidator],
      codigoPostal: [null, [Validators.nullValidator]],
      departamento: [null, Validators.nullValidator],
      paisDomicilio: [null, Validators.nullValidator],
    });

    this.planFormGroup = this.fb.group({
      plan: [null, Validators.required],
    });
    this.hiddenFormGroup = this.fb.group({});
    this.cuponForm = this.fb.group({
      cupon: [null, Validators.nullValidator]
    });
  }


  calculatePrice2() {
    this.busyAppraisalService = true;
    this.recursos = [];
    const cupon: string = this.cuponForm.value.cupon;
    this.lastPricedPlan = this.product.planes.find(plan => plan.id === Number(this.planFormGroup.value.plan));
    if (this.planFormGroup.valid) {
      if (this.complementosForm !== undefined && this.complementosForm.recursos.length > 0) {
        if (this.complementosForm.isValid()) {
          this.recursos = this.complementosForm.recursos;
          this.appraisalService.calculatePrice(this.lastPricedPlan, 1, this.recursos, cupon != null ? cupon : undefined, this.clienteContacto && this.clienteContacto.cliente ? this.clienteContacto.cliente.id : undefined).subscribe(value => {
            this.OnetimeCost = false;
            for (const linea of value.lineasCfe) {
              if (linea.tipo_recurso === 'CONTINGENTE') {
                this.OnetimeCost = true;
                break;
              }
            }
            this.busyAppraisalService = false;
            this.appraisal = value;
            this.makeRound(this.appraisal);
          }, error => {
            this.busy = false;
            this.config = new MatSnackBarConfig();
            this.config.duration = 5000;
            const mensaje = 'Hubo un error al realizar la tasación, Intente mas tarde';
            this.snackbar.open(mensaje, 'Cerrar', this.config);
          });
        } else {
          this.complementosForm.showErrors = true;
        }
      } else {
        this.appraisalService.calculatePrice(this.lastPricedPlan, 1, this.recursos, cupon != null ? cupon : undefined, this.clienteContacto && this.clienteContacto.cliente ? this.clienteContacto.cliente.id : undefined).subscribe(value => {
          this.OnetimeCost = false;
          for (const linea of value.lineasCfe) {
            if (linea.tipo_recurso === 'CONTINGENTE') {
              this.OnetimeCost = true;
              break;
            }
          }
          this.busyAppraisalService = false;
          this.appraisal = value;
          this.cuponForm.patchValue({
            cupon: this.appraisal.lineasCfe[0].codigo_promocion != 'NO_PROMOCION' ? this.appraisal.lineasCfe[0].codigo_promocion : null
          });
          this.makeRound(this.appraisal);
        }, error => {
          this.busy = false;
          this.config = new MatSnackBarConfig();
          this.config.duration = 5000;
          const mensaje = 'Hubo un error al realizar la tasación, Intente mas tarde';
          this.snackbar.open(mensaje, 'Cerrar', this.config);
        });
      }
    }
  }


  calculatePrice() {
    this.busyAppraisalService = true;
    this.recursos = [];
    const cupon: string = this.cuponForm.value.cupon;
    this.lastPricedPlan = this.product.planes.find(plan => plan.id === Number(this.planFormGroup.value.plan));
    if (this.planFormGroup.valid) {
      if (this.complementosForm !== undefined && this.complementosForm.recursos.length > 0) {
        if (this.complementosForm.isValid()) {
          this.recursos = this.complementosForm.recursos;
          this.appraisalService.calculatePrice(this.lastPricedPlan, 1, this.recursos, cupon != null ? cupon : undefined, this.clienteContacto && this.clienteContacto.cliente ? this.clienteContacto.cliente.id : undefined).subscribe(value => {
            this.OnetimeCost = false;
            for (const linea of value.lineasCfe) {
              if (linea.tipo_recurso === 'CONTINGENTE') {
                this.OnetimeCost = true;
                break;
              }
            }
            this.busyAppraisalService = false;
            this.appraisal = value;
            this.makeRound(this.appraisal);
          }, error => {
            this.busy = false;
            this.config = new MatSnackBarConfig();
            this.config.duration = 5000;
            const mensaje = 'Hubo un error al realizar la tasación, Intente mas tarde';
            this.snackbar.open(mensaje, 'Cerrar', this.config);
          });
        } else {
          this.complementosForm.showErrors = true;
        }
      } else {
        this.appraisalService.calculatePrice(this.lastPricedPlan, 1, this.recursos, cupon != null ? cupon : undefined, this.clienteContacto && this.clienteContacto.cliente ? this.clienteContacto.cliente.id : undefined).subscribe(value => {
          this.OnetimeCost = false;
          for (const linea of value.lineasCfe) {
            if (linea.tipo_recurso === 'CONTINGENTE') {
              this.OnetimeCost = true;
              break;
            }
          }
          this.busyAppraisalService = false;
          this.appraisal = value;
          this.cuponForm.patchValue({
            cupon: this.appraisal.lineasCfe[0].codigo_promocion != 'NO_PROMOCION' ? this.appraisal.lineasCfe[0].codigo_promocion : null
          });
          this.makeRound(this.appraisal);
        }, error => {
          this.busy = false;
          this.config = new MatSnackBarConfig();
          this.config.duration = 5000;
          const mensaje = 'Hubo un error al realizar la tasación, Intente mas tarde';
          this.snackbar.open(mensaje, 'Cerrar', this.config);
        });
      }
    }
  }

  obtenerDescuento(): number {
    var descuento = 0;
    for (var i = 0; this.appraisal.lineasCfe.length > i; i++) {
      descuento = this.appraisal.lineasCfe[i].importeDescuentos + descuento;

    }

    return descuento;
  }

  payNow() {
    this.hiddenFormReference.nativeElement.action = 'https://spftest.sistarbanc.com.uy/spfe/servlet/PagoEmpresa';
    this.hiddenFormReference.nativeElement.method = 'post';
    this.hiddenFormReference.nativeElement.submit();
  }

  makeRound(appraisal: Appraisal) {
    appraisal.importeGravadoAnticipado = +appraisal.importeGravadoAnticipado.toFixed(1);
    appraisal.importeTotal = +appraisal.importeTotal.toFixed(1);
    appraisal.importeImpuestos = +appraisal.importeImpuestos.toFixed(1);
    appraisal.importeAnticipado = +appraisal.importeAnticipado.toFixed(1);
    appraisal.importeBruto = +appraisal.importeBruto.toFixed(1);
    // appraisal.importeDescuentos = +appraisal.importeDescuentos.toFixed(1);
    for (const line of appraisal.lineasCfe) {
      line.importeBruto = +line.importeBruto.toFixed(1);
      line.importeDescuentos = +line.importeDescuentos.toFixed(1);
      line.importeImpuestos = +line.importeImpuestos.toFixed(1);
      line.importeTotal = +line.importeTotal.toFixed(1);
      line.montoImponible = +line.importeTotal.toFixed(1);
      line.importeAnticipado = +line.importeAnticipado.toFixed(1);
    }
    this.appraisal = appraisal;
  }

  forceNumberCoparator(a, b) {
    return (a && b && Number(a) === Number(b));
  }
}


@Component({
  selector: 'app-order-message-dialog',
  templateUrl: 'order-message-dialog.html',
})
export class OrderMessageDialog {
  deleteBusy = false;
  returnBusy = false;

  constructor(public dialogRef: MatDialogRef<OrderMessageDialog>,
    public notificationService: NotificationService,
    public orderService: OrderService,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: Notification) {
  }


  deleteAlert() {
    this.deleteBusy = true;
    this.orderService.getOneExternalId(this.data.idExterno).subscribe(order => {
      this.orderService.transitionByAction('FRACASO', order.id).subscribe(trash => {
        this.notificationService.updateNotification(this.data.id, 'borrado', true).subscribe(value => {
          this.deleteBusy = false;
          this.dialogRef.close();
        });
      });
    });
  }

  onReturn() {
    this.returnBusy = true;
    this.orderService.getOneExternalId(this.data.idExterno).subscribe(order => {
      this.orderService.transitionByAction('FRACASO', order.id).subscribe(trash => {
        this.notificationService.updateNotification(this.data.id, 'borrado', true).subscribe(value => {
          this.deleteBusy = false;
          this.dialogRef.close();
          this.router.navigate(['product/' + order.plan.servicio.idExterno + '/purchase/' + order.plan.idExterno], { queryParams: { orderId: order.idExterno } });
        });
      });
    });
  }


}
