import { Component } from "@angular/core";

@Component({
  selector: "app-pharmacare-landing-fourth-section",
  template: ` <h2><span>¡Es</span> para ti!</h2>
    <section class="bottom-reveal" >
      <div class="accordion-item" *ngFor="let faq of faqs">
        <h5 (click)="faq.hidden = !faq.hidden">
          {{ faq.title }}
          <svg
            [class]="{ hidden: faq.hidden }"
            width="18"
            height="12"
            viewBox="0 0 18 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.8125 1.90625L8 8.09375L14.1875 1.90625"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </h5>
        <p [class]="{ hidden: faq.hidden }">{{ faq.desc }}</p>
      </div>
    </section>`,
  host: {
    class: "container-pharma",
    id: 'faqs'
  },
  styleUrls: ["./fourth-section.component.scss"],
})
export class PharmacareLandingFourthSection {
  test = false;
  faqs: { title: string; desc: string; hidden: boolean }[] = [
    {
      title: "¿Cómo realizo la compra de la tarjeta?",
      desc: "La compra es simple y segura desde la página de Mytreat. La compra inicial cubre los primeros 6 meses, y una vez cumplido este plazo, puedes optar por mantener la suscripción o decidir no renovarla.",
      hidden: false,
    },
    {
      title:
        "¿Es conveniente tener Pharmcare Digital Card si viajo a EE.UU. solo ocasionalmente?",
      desc: "Si, ya que la tarjeta no tiene vencimiento mientras se paga la suscripción de US$ 7 por mes y los descuentos obtenidos en cada uso lo valen.",
      hidden: true,
    },
    {
      title:
        "¿Cómo valido mi receta médica si es de un país extranjero (no EE.UU.)?",
      desc: "Si su receta es de un país extranjero, solo necesita enviarla a Pharmcare Services a través de WhatsApp. Ellos le envían la prescripción del medicamento a la farmacia más próxima a su domicilio o si lo prefiere a su lugar de residencia directamente. (Este delivery puede tener un pequeño costo adicional).",
      hidden: true,
    },
    {
      title: "¿Puedo usar la tarjeta para comprar medicamentos controlados? ",
      desc: "NO, para poder comprar un medicamento controlado (psicofármaco), usted debe concurrir a la consulta de un médico local y pedir la prescripción directamente.",
      hidden: true,
    },
  ];
}
