<h2 mat-dialog-title class="mat-typography__underscore">Orden en proceso</h2>
<mat-dialog-content>
  <p>Actualmente tiene una orden en proceso.<br>¿Desea retomarla o descartarla?</p>
</mat-dialog-content>
<div mat-dialog-actions align="end">
  <button mat-stroked-button color="accent" (click)="onReturn()">
    Retomar
    <mat-icon *ngIf="returnBusy">
      <mat-spinner color="accent" diameter="20"></mat-spinner>
    </mat-icon>
  </button>
  <button mat-flat-button color="primary" (click)="deleteAlert()">
    Descartar
    <mat-icon *ngIf="deleteBusy">
      <mat-spinner color="accent" diameter="20"></mat-spinner>
    </mat-icon>
  </button>
</div>
