<!-- {{element?.titulo}}
{{element?.linkValor}}
{{element?.tipoBanner}}
{{element?.imagen}}
{{element?.tipoVisualizacion}}
{{element?.ancho}}
{{element?.orden}}
{{element?.lenguaje}}
{{element?.fechaDesde | date:'shortDate'}}
{{element?.fechaFin | date:'shortDate'}} -->

<div *ngIf="element?.ancho === 0" class="full-width">
    <h1 class="mat-typography__underlined mb-0"  [ngClass]="{'d-block d-sm-none': element?.tipoVisualizacion === 'MOVIL', 'd-none d-sm-block': element?.tipoVisualizacion === 'DESKTOP'}">{{element?.titulo}}</h1>
</div>

<div *ngIf="element?.ancho !== 0" class="col-sm-{{element?.ancho}} p-2" >
    <h1 class="mat-typography__underlined mb-0"  [ngClass]="{'d-block d-sm-none': element?.tipoVisualizacion === 'MOVIL', 'd-none d-sm-block': element?.tipoVisualizacion === 'DESKTOP'}">{{element?.titulo}}</h1>
</div>