import {AfterViewInit, Component, Inject, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {OrderService} from '../../services/order.service';
import {Observable} from 'rxjs';
import {Contrato} from '../../models/contrato.model';
import {ContractService} from '../../services/contract.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Plan} from '../../models/plan.model';
import {ComplementsListComponent} from '../../components/complements-list/complements-list.component';
import {OrdenPlanCompraRecurso} from '../../models/ordenplancomprarecurso.model';
import {PlanRecurso} from '../../models/planRecurso.model';
import {Router} from '@angular/router';
import {Form, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Recurso} from '../../models/recurso.model';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.page.html',
  styleUrls: []
})
export class SubscriptionsPage implements OnInit {
  hardcode: any[];
  suscripciones: Contrato[];
  vacio = false;

  constructor(private contratoService: ContractService,
              private orderService: OrderService,
              public dialog: MatDialog,
              private router: Router) {
  }

  ngOnInit(): void {
    this.contratoService.getAll$().subscribe( value => {
      if (value === null) {
        this.vacio = true;
      }
      this.suscripciones = value;
    });
  }

  redirect(id: number) {
    this.router.navigateByUrl(this.router.url + '/' + id + '/detail');
  }
}

