import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SearchBoxService {
  isShowable: boolean;

  constructor() {
    this.isShowable = true;
  }

  getValue(): boolean{
    return this.isShowable;
  }
  hideSearchBox() {
    this.isShowable = false;
  }
  showSearchBox() {
    this.isShowable = true;
  }

}
