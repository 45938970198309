import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Contact } from 'src/app/models/contact.model';
import { Pais } from 'src/app/models/pais.modelo';
import { ContactService } from 'src/app/services/contact.service';
import { PaisService } from 'src/app/services/pais.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user',
  templateUrl: './user.page.html',
  styleUrls: []
})
export class UserPage implements OnInit {

  userForm: FormGroup;
  user: Contact;
  paises$: Observable<Pais[]>;
  submitted = false;
  public config: MatSnackBarConfig;

  constructor(private userService: ContactService, private paisesService: PaisService,
    private router: Router, private httpClient: HttpClient, public dialog: MatDialog, public snackbar: MatSnackBar) { }

  updateUserAndShowConfirmation(): void {
    if (this.userForm.invalid) {
      return;
    }

    this.userService.updateUserWeb(this.userForm.value).subscribe(
      (response) => {
        // Mostrar un mensaje de confirmación en un Snackbar
        this.snackbar.open('Los datos se han actualizado correctamente.', 'Cerrar', {
          duration: 3000,
        });

        // Abrir un modal de confirmación
        this.dialog.open(ConfirmSubDialog, {
          width: '300px',
          data: { message: 'Los datos se han actualizado correctamente.' }
        });

        // Refrescar los datos del usuario después de actualizar
        this.userService.getUserWeb().subscribe(data => {
          this.userForm.patchValue({
            nombre: data.nombre,
            apellido: data.apellido,
            telefono: data.telefono,
            domicilio: data.domicilio,
            tipoDocumento: data.tipoDocumento,
            numeroDocumento: data.numeroDocumento,
            paisDocumento: data.paisDocumento,
            ciudad: data.ciudad,
            departamento: data.departamento,
            paisDomicilio: data.paisDomicilio,
            codigoPostal: data.codigoPostal
          });
        });
      },
      (error) => {
        // Manejo del error en caso de que la actualización falle
        this.snackbar.open('Hubo un error al actualizar los datos.', 'Cerrar', {
          duration: 3000,
        });
      }
    );
  }


  updateUser() {
    return this.userService.updateUserWeb(this.userForm.value).subscribe(
      (response) => this.ngOnInit(),
      (error) => { }
    )
  }

  idComparator(a, b): boolean {
    if (a && b) {
      return Number(a['id']) === Number(b['id']);
    }
  }

  openDialog(userForm: FormGroup): void {
    this.submitted = true;
    // stop here if form is invalid
    if (this.userForm.invalid) {
      return;
    }
    const dialogRef = this.dialog.open(ConfirmSubDialog, {
      width: '400px',
      data: userForm
    });
    dialogRef.afterClosed().subscribe(
      result => {
        this.submitted = false;
        if (result === 'OK') {
          this.ngOnInit();
        }
        else {
          this.config = new MatSnackBarConfig();
          this.config.duration = 5000;
          let mensaje = 'Hubo un error al guardar. Los datos no se modificaron';
          if (result.constructor === HttpErrorResponse) {
            if (result.error['parameterViolations']) {
              mensaje += ': \n' + result.error['parameterViolations'][0].message;
            }
          }
          this.snackbar.open(mensaje, 'Cerrar', this.config);
        }
      },
      (error) => {
      }
    );
  }

  get f() { return this.userForm.controls; }

  ngOnInit(): void {
    this.paises$ = this.paisesService.getAll$();
    this.userForm = new FormGroup({
      nombre: new FormControl({ value: '', disabled: true }, Validators.required),
      apellido: new FormControl({ value: '', disabled: true }, Validators.required),
      telefono: new FormControl({ value: '', disabled: true }, Validators.nullValidator),
      domicilio: new FormControl({ value: '', disabled: true }, Validators.nullValidator),
      tipoDocumento: new FormControl({ value: '', disabled: true }, Validators.nullValidator),
      numeroDocumento: new FormControl({ value: '', disabled: true }, Validators.nullValidator),
      paisDocumento: new FormControl({ value: '', disabled: true }, Validators.nullValidator),
      ciudad: new FormControl({ value: '', disabled: true }, Validators.nullValidator),
      departamento: new FormControl({ value: '', disabled: true }, Validators.nullValidator),
      paisDomicilio: new FormControl({ value: '', disabled: true }, Validators.nullValidator),
      codigoPostal: new FormControl({ value: '', disabled: true }, Validators.nullValidator),
    });
    this.userForm.enable();
    this.userService.getUserWeb().subscribe(data => {
      this.userForm.patchValue({
        nombre: data.nombre,
        apellido: data.apellido,
        telefono: data.telefono,
        domicilio: data.domicilio,
        tipoDocumento: data.tipoDocumento,
        numeroDocumento: data.numeroDocumento,
        paisDocumento: data.paisDocumento,
        ciudad: data.ciudad,
        departamento: data.departamento,
        paisDomicilio: data.paisDomicilio,
        codigoPostal: data.codigoPostal
      });
    })

  }
}


@Component({
  selector: 'app-confirm-sub-dialog',
  templateUrl: 'confirm-sub-dialog.html',
})
export class ConfirmSubDialog {
  busy = false;

  constructor(
    public dialogRef: MatDialogRef<ConfirmSubDialog>,
    @Inject(MAT_DIALOG_DATA) public userForm: FormGroup,
    public userService: ContactService) {
  }

  onAccept(): void {
    this.dialogRef.close('ACEPTAR');
  }

  confirmUpdateUser(userForm: FormGroup) {
    this.busy = true;
    this.userService.updateUserWeb(this.userForm.value).subscribe(
      (response) => {
        this.busy = false;
        this.dialogRef.close('OK');
      },
      (error) => {
        this.dialogRef.close(error);
      }
    )

  }
}
