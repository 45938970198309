import { Component, Input } from "@angular/core";
import { ProductService } from "@services";
import { Product } from "src/app/models/product.model";

// import Swiper core and required components
import SwiperCore, {
  A11y,
  Autoplay,
  Controller,
  EffectFade,
  Navigation,
  Pagination,
  Scrollbar,
  SwiperOptions,
  Thumbs,
  Virtual,
  Zoom,
} from "swiper/core";

import { Observable } from "rxjs/internal/Observable";
import { switchMap } from "rxjs/internal/operators/switchMap";
import { ActivatedRoute } from "@angular/router";

// install Swiper components
SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller,
  EffectFade,
]);

@Component({
  selector: "app-product-slider",
  templateUrl: "./product-slider.component.html",
})
export class ProductSliderComponent {
  @Input() categoryParent: string;

  //public products: Observable<Product[]>;

  public products: Product[];

  configCarousel: SwiperOptions = {
    slidesPerView: 1,
    navigation: true,
    spaceBetween: 16,
    breakpoints: {
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 4,
      },
    },
  };

  constructor(private productService: ProductService, route: ActivatedRoute) {
    // debugger;
    // this.products = route.queryParams.pipe(
    //   switchMap((params) =>
    //     this.productService.getProductsByCategoryWithOrder$(
    //       this.categoryParent,
    //       params.tipo_orden,
    //       params.ordenar
    //     )
    //   )
    // );
  }

  ngOnInit(): void {
    this.productService
      .getProductsByCategoryWithOrder$(this.categoryParent, "ORDEN", "ASC")
      .subscribe((data) => {
        this.products = data;
      });
  }
}
