import {Injectable} from '@angular/core';
import {Meta} from '@angular/platform-browser';
import {Category} from '@models';

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  constructor(private meta: Meta) {
    this.meta.addTags([
      {name: 'og:site_name', content: 'Aleph MarketPlace'},
      {name: 'og:title', content: ''},
      {name: 'og:description', content: ''},
      {name: 'og:type', content: 'website'},
      {name: 'og:updated_time', content: new Date().getTime().toString()},
    ], true);
  }

  public setMetaCategory(category: Category) {
    this.meta.addTags([
      {name: 'og:site_name', content: 'Aleph MarketPlace'},
      {name: 'og:title', content: category.nombre},
      {name: 'og:description', content: ''},
      // {name: 'og:image', content: '@alligatorio'}
      {name: 'og:type', content: 'website'},
      {name: 'og:updated_time', content: new Date().getTime().toString()},
    ], true);
  }
}
