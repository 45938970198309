import { Component, OnInit } from '@angular/core';
import { ElementService } from 'src/app/services/element.service';
import { Elemento } from 'src/app/models/elemento.model';
import { ElementoFiltro } from 'src/app/models/elementoFiltro.model';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { PlatformLocation } from "@angular/common";
import { AbstractPage } from '../../abstract.page';
import { SearchBoxService } from 'src/app/services/search-box.service';
import { LangService } from "src/app/services/lang.service";

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent extends AbstractPage  implements OnInit {
  // IsEnglish = false;
  isEnglish = false;
  elementos$: Elemento[] = [];
  limite = 100;  
  desplazamiento = 0;  

  constructor(private elementoService: ElementService,
    private sanitizer: DomSanitizer,
    private platformLocation: PlatformLocation,
    private searchBoxService: SearchBoxService,
    private langService : LangService,
  ) {
    super();
    this.searchBoxService.showSearchBox();
  }

  public pageTitle(): string {
    return environment.title;
  }

  ngOnInit(): void {
    this.loadEvents();

    this.langService.getLangV2().subscribe((lang) => {
      this.isEnglish = lang.toLowerCase().trim() === "en";
    });
    console.log(this.isEnglish)
  }
  private async loadEvents() {
    try {
      const filtroEventos: ElementoFiltro = new ElementoFiltro();
      filtroEventos.estado = "activo";
      filtroEventos.modulo = "Eventos";
      filtroEventos.categorias = [];

      const data = await this.elementoService.getAll(this.limite, this.desplazamiento, filtroEventos, '').toPromise();
      if (data && data.elementos) {
        this.elementos$ = data.elementos;
      } else {
        // console.error('No se encontraron eventos');
      }
    } catch (error) {
      console.error('Error al obtener eventos:', error);
    }
  }
  public sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
