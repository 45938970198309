<div class="appDetailsContent">
  <div class="breadcrumb">
    <!-- appCats -->
    <div class="container">
      <a [routerLink]="'/'" i18n="Home">Home</a>
      <span> / </span> <span i18n="Manage">Manage</span> <span> / </span> <span i18n="invoice">Invoice</span>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-12 mt-5 mb-4">
      <h1 class="mat-typography__underscore" i18n="invoice">Invoices</h1>
    </div>
  </div>
  <div class="container">
    <div class="filas">
      <div class="col-sm">
        <div class="filtro">
          <mat-label class="descCampo" invoice i18n="status">Status</mat-label>
          <mat-select class="campo" [(ngModel)]="estado">
            <mat-option value="LIQUIDADO" i18n="LiquidadoOptionStatus | Liquidado">PRE INVOICE</mat-option>
            <mat-option value="FACTURADO" i18n="FACTURADOOptionStatus | FACTURADO">INVOICED</mat-option>
            <mat-option value="COBRADO" i18n="COBRADOOptionStatus | COBRADO">PAYED</mat-option>
            <mat-option value="NINGUNO" i18n="NINGUNOOptionStatus | NINGUNO">WITHOUT FILTER</mat-option>
          </mat-select>
        </div>

      </div>
      <div class="col-sm">
        <div class="filtro">
        <mat-label i18n="rangoInvoices | Rango" class="descCampo">Range</mat-label>
        <mat-select class="campo" [(ngModel)]="rangoDias">
          <mat-option i18n="ultimos30dias | ÚLTIMOS 30 DÍAS" value="30">LAST 30 DAYS</mat-option>
          <mat-option   i18n="ultimos60dias | ÚLTIMOS 60 DÍAS" value="60">LAST 60 DAYS</mat-option>
          <mat-option i18n="ultimos30dias | COBRADO" value="90">LAST 90 DAYS</mat-option>
          <mat-option i18n="ultimoAnio | ÚLTIMO AÑO" value="365"> LAST YEAR</mat-option>
          <mat-option  i18n="personalizado | PERSONALIZADO" value="RANGO">CUSTOM</mat-option>
          <mat-option i18n="ninguno | NINGUNO" value="NINGUNO">WITHOUT RANGE</mat-option>
        </mat-select>
        </div>
      </div>
      <div *ngIf="rangoDias=='RANGO'" class="col-sm">
        <div class="filtro">
        <input  matInput [matDatepicker]="picker" i18n-placeholder="desdeInvoices | Desde" placeholder="Since" [(ngModel)]="fechaInicio">
        <mat-datepicker-toggle *ngIf="rangoDias=='RANGO'" matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>
      </div>
      <div *ngIf="rangoDias=='RANGO'"  class="col-sm">
        <div class="filtro">
        <input  matInput [matDatepicker]="picker1"  i18n-placeholder="hastaInvoices | hasta"  placeholder="Until" [(ngModel)]="fechaFin">
        <mat-datepicker-toggle class="filtroGrupo" *ngIf="rangoDias=='RANGO'" matSuffix [for]="picker1">
        </mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
        </div>
      </div>
      <div class="col-sm">
        <div class="botonBusqueda">
          <mat-card-content >
            <button i18n="SearchButtonInvoices | Boton Buscar en Pagos" [disabled]='busy' mat-flat-button color="accent" (click)="buscar()">Search
              <mat-icon *ngIf="busy">
                <mat-spinner color="red" diameter="20"></mat-spinner>
              </mat-icon>
            </button>
          </mat-card-content>
        </div>

      </div>

    </div>
  </div>

  <!-- begin Invoice Table -->
  <table mat-table [dataSource]="dataSource"  class="mat-table--numbers mat-elevation-0">
    <ng-container matColumnDef="invoiceNumber">
      <th mat-header-cell  *matHeaderCellDef i18n="NroPaymentPayments | NroPago columna en pagos">N° Payment </th>
      <td mat-cell *matCellDef="let element"> {{element?.id}} </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef i18n="CreationDatePayments | FechaCreacion columna en pagos">Creation Date </th>
      <td mat-cell *matCellDef="let element"> {{element?.fechaFactura | date:'shortDate'}} </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef i18n="StatusPayments | Estado columna en pagos">Status </th>
      <td mat-cell *matCellDef="let element"> {{element?.estado}} </td>
    </ng-container>

    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef i18n="ValuePayments | Valor columna en pagos">Value </th>
      <td mat-cell *matCellDef="let element" class="mat-cell--value"> {{element?.importeTotal.toFixed(2) }} {{element?.moneda.codigo}} </td>
    </ng-container>

    <ng-container matColumnDef="acciones">
      <th mat-header-cell *matHeaderCellDef > </th>
      <td mat-cell *matCellDef="let element">
        <button *ngIf=" element?.estado=='LIQUIDADO' || element?.estado=='FACTURADO'" (click)="pagar(element)"
        mat-flat-button color="primary" i18n="btnPayInvoice | Boton pagar Factura">Pay</button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [length]="length" *ngIf="paginable" (page)="siguientePagina($event)"
    [pageSizeOptions]="[10, 20, 30, 50]" showFirstLastButtons aria-label="Select page of invoices"></mat-paginator>
</div>
