import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-banner-personalizado',
  templateUrl: './banner-personalizado.component.html',
  styleUrls: ['./banner-personalizado.component.scss']
})
export class BannerPersonalizadoComponent implements OnInit {

  @Input() element:any;
  constructor() { }

  ngOnInit(): void {
  }

}
