import { AfterViewInit, Component, Input } from "@angular/core";

@Component({
  selector: "app-pharmacare-landing-first-section",
  template: `
    <h2 (click)="onTitlePress('benefits')">Beneficios</h2>
    <section id="benefits" class="container-pharma">
      <article class="card left-reveal">
        <div class="title">
          <img
            src="assets/landings/pharmacare/icons/facil.png"
            alt="Fácil de usar"
            width="40px"
            height="40px"
          />
          <h3>Fácil de usar</h3>
        </div>
        <p>
          Pharmcare Digital Card es completamente virtual. Una vez que la
          adquieres, recibes un código digital en tu celular que te permite
          acceder a los descuentos sin necesidad de una tarjeta física.
        </p>
      </article>
      <article class="card right-reveal">
        <div class="title">
          <img
            src="assets/landings/pharmacare/icons/descuentos.png"
            alt="Descuentos en Medicamentos"
            width="40px"
            height="40px"
          />
          <h3>Descuentos en Medicamentos</h3>
        </div>
        <p>
          Con Pharmcare Digital Card puedes obtener descuentos significativos en
          la compra de medicamentos en la más amplia red de farmacias de Estados
          Unidos. Este ahorro es especialmente útil para quienes viajan y
          necesitan acceder a medicación durante su estancia en el país.
        </p>
      </article>
      <article class="card left-reveal">
        <div class="title">
          <img
            src="assets/landings/pharmacare/icons/validacion.png"
            alt="Validación de Recetas, ideal para viajeros"
            width="45px"
            height="45px"
          />
          <h3>Validación de Recetas, ideal para viajeros</h3>
        </div>
        <p>
          A diferencia de otras tarjetas de descuento para medicamentos,
          Pharmcare Digital Card está disponible para turistas y viajeros
          internacionales. Si tienes una receta médica, solo necesitas enviarla
          por Whatsapp a Pharmcare Services, y ellos la validan para que puedas
          adquirir tu medicamento en farmacias de Estados Unidos.
        </p>
      </article>
      <article class="card right-reveal">
        <div class="title">
          <img
            src="assets/landings/pharmacare/icons/accesible.png"
            alt="Accesible"
            width="40px"
            height="40px"
          />
          <h3>Accesible</h3>
        </div>
        <p>
          Por solo US$7 al mes, puedes disfrutar de todos los beneficios sin
          compromisos a largo plazo, es para usar cuando la necesites.
        </p>
      </article>
      <article class="card bottom-reveal">
        <div data-item="1">
          <div class="animated-number "><span>80</span>%</div>
          <p>Hasta un 80% de ahorro</p>
        </div>
        <div data-item="2">
          <div class="animated-number"><span>60</span>K</div>
          <p>Farmacias asociadas</p>
        </div>
        <div data-item="3">
          <div class="animated-number"><span>24</span>hs</div>
          <p>Validación de recetas en menos de 24 horas</p>
        </div>
        <div data-item="4">
          <div class="animated-number">$<span>7</span></div>
          <p>Solo 7 USD al mes</p>
        </div>
      </article>
      <div class="pagination">
        <span data-item="1"></span>
        <span data-item="2"></span>
        <span data-item="3"></span>
        <span data-item="4"></span>
      </div>
    </section>
  `,
  styleUrls: ["./first-section.component.scss"],
})
export class PharmacareLandingFirstSection implements AfterViewInit {
  @Input() onTitlePress: (id: string) => void;

  ngAfterViewInit() {
    const elems = document.querySelectorAll(".animated-number span");
    elems.forEach((elem) => {
      this.animateValue(elem as HTMLElement, Number(elem.innerHTML), 0, 2000);
    });

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const itemNumber = entry.target.getAttribute("data-item");
          const indicator = document.querySelector(
            `span[data-item='${itemNumber}']`
          );
          if (entry.isIntersecting) {
            indicator.classList.add("in-view");
          } else {
            indicator.classList.remove("in-view");
          }
        });
      },
      {
        threshold: 0.65, // Adjust the threshold as needed
      }
    );

    document.querySelectorAll("div[data-item]").forEach((element) => {
      observer.observe(element);
    });
  }

  private animateValue(
    obj: HTMLElement,
    start: number,
    end: number,
    duration: number
  ) {
    let startTimestamp = null;
    const step = (timestamp: number) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      obj.innerHTML = Math.floor(progress * (start - end) + end).toString();
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }
}
