<app-pharmacare-landing-header [navItems]="[
{link: 'benefits', name:'Beneficios'},
{link: 'information', name:'Información'},
{link: 'faqs', name:'FAQs'}]"
[buyLink]="squareLink"
(navigate)="scrollTo($event)">
</app-pharmacare-landing-header>
<app-pharmacare-landing-hero [buyLink]="squareLink"></app-pharmacare-landing-hero>
<app-pharmacare-landing-first-section [onTitlePress]="scrollTo"></app-pharmacare-landing-first-section>
<app-pharmacare-landing-form-section [buyLink]="squareLink"></app-pharmacare-landing-form-section>
<app-pharmacare-landing-third-section></app-pharmacare-landing-third-section>
<app-pharmacare-landing-fourth-section></app-pharmacare-landing-fourth-section>
<app-pharmacare-landing-footer [buyLink]="squareLink"></app-pharmacare-landing-footer>

