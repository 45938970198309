<app-landing-header
  [navItems]="[
    {link: 'benefits', name:'Beneficios'},
    {link: 'information', name:'Información'},
    {link: 'professional-support', name:'Respaldo'},
    {link: 'testimonials', name:'Testimonios'}
  ]"
  [buyLink]="squareLink"
  (navigate)="scrollTo($event)"
></app-landing-header>
<main>
  <app-hero-tbs></app-hero-tbs>
  <section id="benefits" class="container-tbs">
    <h2 (click)="scrollTo('benefits')">Beneficios</h2>
    <div class="grid">
      <article class="centered bottom-reveal">
        <img
          src="assets/landings/tbs/icons/benefits-icon-1.png"
          alt="Estudio Exhaustivo y No Invasivo"
          width="62px"
          height="62px"
        />
        <h6>Estudio Exhaustivo y No Invasivo</h6>
        <p>
          Nuestro Total Body Scan permite un análisis completo de sus órganos
          nobles, en menos de 30 minutos.
        </p>
      </article>
      <article class="centered bottom-reveal">
        <img
          src="assets/landings/tbs/icons/benefits-icon-2.png"
          alt="Tecnología de Última Generación"
          width="62px"
          height="62px"
        />
        <h6>Tecnología de Última Generación</h6>
        <p>
          CT Scan abierto que ofrece imágenes tridimensionales de alta precisión
          y de acuerdo con las normas de la Asociación Americana de Radiología.
        </p>
      </article>
      <article class="centered bottom-reveal">
        <img
          src="assets/landings/tbs/icons/benefits-icon-3.png"
          alt="Profesionales Expertos"
          width="62px"
          height="62px"
        />
        <h6>Profesionales Expertos</h6>
        <p>
          Contamos con un equipo Médico y Técnico certificado y entrenado para
          atender pacientes residentes e internacionales.
        </p>
      </article>
      <article class="centered bottom-reveal">
        <img
          src="assets/landings/tbs/icons/benefits-icon-4.png"
          alt="Concierge Personalizado"
          width="62px"
          height="62px"
        />
        <h6>Concierge Personalizado</h6>
        <p>
          Un concierge especializado guía al paciente desde la coordinación y
          preparación hasta la presentación del informe de los resultados y
          pasos a seguir si corresponde.
        </p>
      </article>
      <article class="centered bottom-reveal">
        <img
          src="assets/landings/tbs/icons/benefits-icon-5.png"
          alt="Agenda Personalizada"
          width="62px"
          height="62px"
        />
        <h6>Agenda Personalizada</h6>
        <p>
          Nos adaptamos a sus necesidades y horarios, ofreciéndole una agenda
          flexible para realizar el estudio en el momento más conveniente para
          usted.
        </p>
      </article>
      <article class="centered bottom-reveal">
        <img
          src="assets/landings/tbs/icons/benefits-icon-6.png"
          alt="Resultados Claros "
          width="62px"
          height="62px"
        />
        <h6>Resultados Claros</h6>
        <p>
          Le entregamos un informe detallado y la orientación médica
          personalizada.
        </p>
      </article>
    </div>
    <div class="grid" id="information">
      <div class="photo left-reveal">
        <img
          src="assets/landings/tbs/images/benefits-img-1.png"
          alt="Más Sobre el Total Body Scan"
        />
      </div>
      <article class="desc right-reveal">
        <h4>Más Sobre el Total Body Scan</h4>
        <p>
          El Total Body Scan es un estudio completo que brinda imágenes
          tridimensionales del real estado de todos los órganos nobles,
          permitiendo diagnosticar señales tempranas de alguna alteración
          patológica que pueda existir en el organismo. Es un procedimiento
          rápido, sencillo, no invasivo que permite tomar decisiones rápidas en
          caso de surgir alguna condición medica que lo requiera.
        </p>
        <h5 (click)="toggleShowMore()">
          Más información
          <svg
            [class]="{show: showMoreInfo}"
            width="14"
            height="8"
            viewBox="0 0 16 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.8125 1.90625L8 8.09375L14.1875 1.90625"
              stroke="black"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </h5>
      </article>
      <div [class]="{show: showMoreInfo, 'more-info': true}">
        <ul>
          <li>
            <span>Detección temprana:</span> Conocer el estado de los diferentes
            órganos del cuerpo, detectar en forma temprana diversas alteraciones
            que puedan existir como: malformaciones arterio-venosas, tumores,
            ganglios, quistes, cálculos, aneurismas, procesos degenerativos y
            más.
          </li>
          <li>
            <span> Estudio no invasivo:</span> Tiene una duración aproximada de
            30 minutos, en un Scanner abierto, sin contacto físico, uso de
            inyectables ni punciones. Se suministra una dosis de bario (sabor
            vainilla) previo al estudio para ver imágenes por contraste del
            aparato digestivo.
          </li>

          <li>
            <span> Resultados:</span> El paciente recibe en 24-48 horas un
            informe detallado órgano por órgano, mas el score da calcio de todas
            las arterias, con la traducción al español y la orientación mèdica
            personalizada (coaching).
          </li>
        </ul>
        <div>
          <p>
            <span>Total Body Scan:</span>El Total Body Scan – Your Inside Selfie
            ofrece un estudio exhaustivo y no invasivo del estado de salud de
            sus órganos nobles que se realiza en menos de 30 minutos. Utilizamos
            tecnología de ultima generación, con equipos modernos que siguen las
            normas de la Asociación Americana de Radiología, asegurando un
            servicio de alta calidad. Contamos con un equipo de Médicos y
            Técnicos radiólogos certificados y con más de 25 años de experiencia
            que junto a un equipo de atención especializada para en pacientes
            internacionales garantizan un experiencia cómoda y profesional.
            Además brindamos el servicio de Concierge Médico donde un asistente
            profesional acompaña al paciente en todo el proceso, desde la
            coordinación del estudio, la realización del mismo hasta la entrega
            de resultados con la orientación médica y pasos a seguir, si fuera
            necesario. Nos adaptamos a las necesidades y horarios de cada
            paciente, ofreciendo una agenda flexible y personalizada.
          </p>
        </div>
      </div>
    </div>
  </section>
  <section id="form-section">
    <div class="container-tbs">
      <div>
        <h2><span>¡Agende</span> su estudio!</h2>
        <p>
          Agende tu Total Body Scan y toma medidas para asegurar tu bienestar
          futuro. Una vez realizada la compra, te contactaremos para coordinar
          tu estudio a la <span>brevedad.</span>
        </p>
        <div class="ctas">
          <button class="button-animated">
            <a target="_self" href="https://square.link/u/vr769yh2">Comprar</a>
          </button>
          <button class="button-animated">
            <a
              target="_self"
              href="https://api.whatsapp.com/send?phone=59899389189&type=phone_number&app_absent=0"
              >Whatsapp</a
            >
          </button>
        </div>
      </div>
      <div style="position: absolute; top: -85px; right: 10px; width: 470px">
        <app-landing-contactus-form
          formTipo="landing-tbs"
        ></app-landing-contactus-form>
      </div>
    </div>
  </section>
  <section id="professional-support" class="container-tbs">
    <h2 (click)="scrollTo('professional-support')">
      <span>Respaldo</span> profesional
    </h2>
    <div class="grid">
      <div class="ps-info">
        <div class="ps-info-item left-reveal">
          <div>
            <div class="icon-container">
              <img
                src="assets/landings/tbs/icons/professional-support-icon-1.png"
                alt="Respaldo Institucional"
              />
            </div>
            <h5>Respaldo Institucional:</h5>
          </div>
          <p>
            Nuestro servicio está respaldado por DMI, líder en diagnóstico
            médico en Miami, reconocido por su excelencia y tecnología de punta:
            haría un hipervínculo para más detalle 
          </p>
        </div>
        <div class="ps-info-item left-reveal">
          <div>
            <div class="icon-container">
              <img
                src="assets/landings/tbs/icons/professional-support-icon-2.png"
                alt="Servicio de Concierge Médico"
              />
            </div>
            <h5>Servicio de Concierge Médico:</h5>
          </div>
          <p>
            Un concierge te acompañará desde la reserva hasta la entrega de los
            resultados, asegurándose de que entiendas cada paso y te sientas
            apoyado durante todo el proceso.
          </p>
        </div>
      </div>
      <div class="photo-2 right-reveal">
        <img
          src="assets/landings/tbs/images/professional-support-img-1.png"
          alt="Professional Support"
        />
      </div>
    </div>
  </section>
  <section class="container-tbs" id="testimonials">
    <h2 (click)="scrollTo('testimonials')">Testimonios</h2>
    <div class="grid">
      <article class="bottom-reveal" data-item="1">
        <p>
          El equipo de MyTreat me hizo sentir cómoda y tranquila durante todo el
          proceso. El escaneo fue minucioso y eficiente, y los resultados fueron
          explicados de manera clara y concisa.
        </p>
        <p class="author">- Fátima, 71, Brasil</p>
      </article>
      <article class="bottom-reveal" data-item="2">
        <p>
          El total body scan fue el mejor regalo en años. Permite prevenir
          enfermedades viendo los puntos vulnerables. Mejor aún la atención de
          mytreat.health que brinda una atención personalizada.
        </p>
        <p class="author">– Martín, Argentina</p>
      </article>
      <article class="bottom-reveal" data-item="3">
        <p>
          Soy corredor de medio fondo amateur y el Total Body Scan me pareció el
          mejor chequeo para poder hacer deporte con tranquilidad. Realmente
          excelente. Sin contar que además es fácil reservar la hora y
          comprarlo.
        </p>
        <p class="author">– Leonardo, 54, Uruguay</p>
      </article>
    </div>
    <div class="pagination">
      <span data-item="1"></span>
      <span data-item="2"></span>
      <span data-item="3"></span>
    </div>
  </section>
</main>
<app-footer-tbs></app-footer-tbs>
