import { Component, OnInit, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-footer-tbs",
  template: `
    <footer class="landing-footer">
      <h2><span>¡Agende</span> su estudio!</h2>
      <p class="landing-footer__desc">“Conocer el real estado de salud es lo más importante”</p>
      <p class="landing-footer__desc">
      Una vez confirmada la compra, nos contactaremos a la brevedad para coordinar su estudio.</p>
      <button class="button-animated"><a target="_blank" href="https://square.link/u/vr769yh2">Comprar</a></button>
    </footer>
  `,
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
