<div class="container">
  <div class="row justify-content-center pb-5">
    <div class="col-8 text-justify">
      <h1 class="pt-5">PRIVACY POLICY</h1>
      <p>
        This privacy notice for Mia Wellness and Healthcare International LLC (doing
        business as My Treat) ("My Treat," "we," "us," or "our"), describes how and why
        we might collect, store, use, and/or share ("process") your information when you use
        our services ("Services"), such as when you:<br />
        ■ Visit our website at www.mytreat.health, or any website of ours that links to this
        privacy notice.<br />
        ■ Engage with us in other related ways, including any sales, marketing, or events<br />
        Questions or concerns? Reading this privacy notice will help you understand your
        privacy rights and choices. If you do not agree with our policies and practices, please
        do not use our Services. If you still have any questions or concerns, please contact
        us at info@mytreat.health
      </p>
      <p>
        1. WHAT INFORMATION DO WE COLLECT?
        We collect personal information that you voluntarily provide to us when you register
        on the Services, express an interest in obtaining information about us or our products
        and Services, when you participate in activities on the Services, or otherwise when
        you contact us. Personal Information Provided by You. The personal information
        that we collect depends on the context of your interactions with us and the Services,
        the choices you make, and the products and features you use. The personal
        information we collect may include the following:<br />
        ■ names<br />
        ■ phone numbers<br />
        ■ email addresses<br />
        ■ mailing addresses<br />
        ■ contact preferences<br />
        Sensitive Information. We do not process sensitive information.<br />
        Information automatically collected<br />
        We automatically collect certain information when you visit, use, or navigate the
        Services. This information does not reveal your specific identity (like your name or
        contact information) but may include device and usage information, such as your IP
        address, browser and device characteristics, operating system, language preferences,
        referring URLs, device name, country, location, information about how and when
        you use our Services, and other technical information. This information is primarily
        needed to maintain the security and operation of our Services, and for our internal
        analytics and reporting purposes.
        The information we collect includes:<br />
        ■ Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and
        performance information our servers automatically collect when you access or use
        our Services and which we record in log files. Depending on how you interact with
        us, this log data may include your IP address, device information, browser type, and
        settings and information about your activity in the Services (such as the date/time
        stamps associated with your usage , pages and files viewed, searches, and other
        actions you take such as which features you use), device event information (such as
        system activity, error reports (sometimes called "crash dumps"), and hardware
        settings).<br />
        ■ Device Data. We collect device data such as information about your computer,
        phone, tablet, or other device you use to access the Services. Depending on the
        device used, this device data may include information such as your IP address (or
        proxy server), device and application identification numbers, location, browser type,
        hardware model, Internet service provider and/or mobile carrier, operating system,
        and system configuration information.<br />
        ■ Location Data. We collect location data such as information about your device's
        location, which can be either precise or imprecise. How much information we collect
        depends on the type and settings of the device you use to access the Services. For
        example, we may use GPS and other technologies to collect geolocation data that
        tells us your current location (based on your IP address). You can opt out of allowing
        us to collect this information either by refusing access to the information or by
        disabling your Location setting on your device. However, if you choose to opt out,
        you may not be able to use certain aspects of the Services.<br />
      </p>
      <p>
        2. HOW DO WE PROCESS YOUR INFORMATION?
        We process your personal information for a variety of reasons, depending on how
        you interact with our Services, including:<br />
        ■ To facilitate account creation and authentication and otherwise manage user
        accounts. We may process your information so you can create and log in to your
        account, as well as keep your account in working order.<br />
        ■ To deliver and facilitate delivery of services to the user. We may process your
        information to provide you with the requested service.<br />
        ■ To respond to user inquiries/offer support to users. We may process your
        information to respond to your inquiries and solve any potential issues you might
        have with the requested service.<br />
        ■ To send administrative information to you. We may process your information to
        send you details about our products and services, changes to our terms and policies,
        and other similar information.<br />
        ■ To fulfill and manage your orders. We may process your information to fulfill and
        manage your orders, payments, returns, and exchanges made through the Services.<br />
        ■To save or protect an individual's vital interest. We may process your information
        when necessary to save or protect an individual's vital interest, such as to prevent
        harm.<br />
      </p>
      <p>
        2. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR
        INFORMATION?
        If you are located in the EU or UK, this section applies to you.
        The General Data Protection Regulation (GDPR) and UK GDPR require us to
        explain the valid legal bases we rely on in order to process your personal
        information. As such, we may rely on the following legal bases to process your
        personal information:<br />
        ■ Consent. We may process your information if you have given us permission (i.e.,
        consent) to use your personal information for a specific purpose. You can withdraw
        your consent at any time.<br />
        ■ Performance of a Contract. We may process your personal information when we
        believe it is necessary to fulfill our contractual obligations to you, including
        providing our Services or at your request prior to entering into a contract with you.<br />
        ■ Legal Obligations. We may process your information where we believe it is
        necessary for compliance with our legal obligations, such as to cooperate with a law
        enforcement body or regulatory agency, exercise or defend our legal rights, or
        disclose your information as evidence in litigation in which we are involved.<br />
        ■ Vital Interests. We may process your information where we believe it is necessary
        to protect your vital interests or the vital interests of a third party, such as situations
        involving potential threats to the safety of any person.<br />
        If you are located in Canada, this section applies to you<br />
        We may process your information if you have given us specific permission (i.e.,
        express consent) to use your personal information for a specific purpose, or in
        situations where your permission can be inferred (i.e., implied consent). You can
        withdraw your consent at any time. In some exceptional cases, we may be legally
        permitted under applicable law to process your information without your consent,
        including, for example:<br />
        ■ If collection is clearly in the interests of an individual and consent cannot be
        obtained in a timely way.<br />
        ■ For investigations and fraud detection and prevention.<br />
        ■ For business transactions provided certain conditions are met.<br />
        ■ If it is contained in a witness statement and the collection is necessary to assess,
        process, or settle an insurance claim.<br />
        ■ For identifying injured, ill, or deceased persons and communicating with next of
        kin.<br />
        ■ If we have reasonable grounds to believe an individual has been, is, or may be
        victim of financial abuse.<br />
        ■ If it is reasonable to expect collection and use with consent would compromise the
        availability or the accuracy of the information and the collection is reasonable for
        purposes related to investigating a breach of an agreement or a contravention of the
        laws of Canada or a province.<br />
        ■ If disclosure is required to comply with a subpoena, warrant, court order, or rules
        of the court relating to the production of records.<br />
        ■ If it was produced by an individual in the course of their employment, business,
        or profession and the collection is consistent with the purposes for which the
        information was produced.<br />
        ■ If the collection is solely for journalistic, artistic, or literary purposes.<br />
        ■ If the information is publicly available and is specified by the regulations.<br />
      </p>
      <p>
        3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
        INFORMATION?
        We may share information in specific situations described in this section and/or with
        the following third parties. Vendors, Consultants, and Other Third-Party Service
        Providers. We may share your data with third-party vendors, service providers,
        contractors , or agents ("third parties") who perform services for us or on our behalf
        and require access to such information to do that work. We have contracts in place
        with our third parties, which are designed to help safeguard your personal
        information. This means that they cannot do anything with your personal
        information unless we have instructed them to do it. They will also not share your
        personal information with any organization apart from us. They also commit to
        protect the data they hold on our behalf and to retain it for the period we instruct.
        The third parties we may share personal information with are as follows: [INSERT
        VENDORS]<br />
        We also may need to share your personal information in the following situations:<br />
        ■ Business Transfers. We may share or transfer your information in connection with,
        or during negotiations of, any merger, sale of company assets, financing, or
        acquisition of all or a portion of our business to another company.<br />
        ■ Affiliates. We may share your information with our affiliates, in which case we
        will require those affiliates to honor this privacy notice . Affiliates include our parent
        company and any subsidiaries, joint venture partners, or other companies that we
        control or that are under common control with us.<br />
        ■ Business Partners. We may share your information with our business partners to
        offer you certain products, services, or promotions.<br />
      </p>
      <p>
        5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
        The Services may link to third-party websites, online services, or mobile
        applications and/or contain advertisements from third parties that are not affiliated
        with us and which may link to other websites, services, or applications. Accordingly,
        we do not make any guarantee regarding any such third parties, and we will not be
        liable for any loss or damage caused by the use of such third-party websites, services,
        or applications. The inclusion of a link towards a third-party website, service, or
        application does not imply an endorsement by us. We cannot guarantee the safety
        and privacy of data you provide to any third parties. Any data collected by third
        parties is not covered by this privacy notice. We are not responsible for the content
        or privacy and security practices and policies of any third parties, including other
        websites, services, or applications that may be linked to or from the Services. You
        should review the policies of such third parties and contact them directly to respond
        to your questions.
      </p>
      <p>
        6. HOW LONG DO WE KEEP YOUR INFORMATION?
        We will only keep your personal information for as long as it is necessary for the
        purposes set out in this privacy notice, unless a longer retention period is required or
        permitted by law (such as tax, accounting, or other legal requirements). No purpose
        in this notice will require us keeping your personal information for longer than the
        period of time in which users have an account with us.
        When we have no ongoing legitimate business need to process your personal
        information, we will either delete or anonymize such information, or, if this is not
        possible (for example, because your personal information has been stored in backup
        archives), then we will securely store your personal information and isolate it from
        any further processing until deletion is possible.
      </p>
      <p>
        7. HOW DO WE KEEP YOUR INFORMATION SAFE?
        We have implemented appropriate and reasonable technical and organizational
        security measures designed to protect the security of any personal information we
        process. However, despite our safeguards and efforts to secure your information, no
        electronic transmission over the Internet or information storage technology can be
        guaranteed to be 100% secure, so we cannot promise or guarantee that hackers,
        cybercriminals, or other unauthorized third parties will not be able to defeat our
        security and improperly collect, access, steal, or modify your information. Although
        we will do our best to protect your personal information, transmission of personal
        information to and from our Services is at your own risk. You should only access
        the Services within a secure environment.
      </p>
      <p>
        8. DO WE COLLECT INFORMATION FROM MINORS?
        We do not knowingly solicit data from or market to children under 18 years of age.
        By using the Services, you represent that you are at least 18 or that you are the parent
        or guardian of such a minor and consent to such minor dependent's use of the
        Services. If we learn that personal information from users less than 18 years of age
        has been collected, we will deactivate the account and take reasonable measures to
        promptly delete such data from our records. If you become aware of any data we
        may have collected from children under age 18, please contact us at
        info@mytreat.health
      </p>
      <p>
        9. WHAT ARE YOUR PRIVACY RIGHTS?
        In some regions (like the EEA, UK, and Canada), you have certain rights under
        applicable data protection laws. These may include the right (i) to request access and
        obtain a copy of your personal information, (ii) to request rectification or erasure;
        (iii) to restrict the processing of your personal information; and (iv) if applicable, to
        data portability. In certain circumstances, you may also have the right to object to
        the processing of your personal information. You can make such a request by
        contacting us by using the contact details provided in the section "HOW CAN YOU
        CONTACT US ABOUT THIS NOTICE?" below.<br />
        We will consider and act upon any request in accordance with applicable data
        protection laws.<br />
        If you are located in the EEA or UK and you believe we are unlawfully processing
        your personal information , you also have the right to complain to your local data
        protection supervisory authority. You can find their contact details here:
        https://ec.euroRa.eu/justice /data-R.rotection/bodies/author ities/index en.htm.
        If you are located in Switzerland, the contact details for the data protection
        authorities are available here: httRs://www.edoeb.adm in.ch/edoeb/en/home.html.
        Withdrawing your consent: If we are relying on your consent to process your
        personal information, which may be express and/or implied consent depending on
        the applicable law, you have the right to withdraw your consent at any time. You
        can withdraw your consent at any time by contacting us by using the contact details
        provided in the section "HOW CAN YOU CONTACT US ABOUT THIS
        NOTICE?" below or updating your preferences.<br />
        However, please note that this will not affect the lawfulness of the processing before
        its withdrawal nor, when applicable law allows, will it affect the processing of your
        personal information conducted in reliance on lawful processing grounds other than
        consent. OP-ting out of marketing and Promotional communications: You can
        unsubscribe from our marketing and promotional communications at any time by
        clicking on the unsubscribe link in the emails that we send, or by contacting us using
        the details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS
        NOTICE?" below. You will then be removed from the marketing lists.
        However, we may still communicate with you - for example, to send you servicerelated
        messages that are necessary for the administration and use of your account,
        to respond to service requests, or for other non-marketing purposes.
        Account Information<br />
        If you would at any time like to review or change the information in your account or
        terminate your account, you can:<br />
        ■ Log in to your account settings and update your user account.<br />
        Upon your request to terminate your account, we will deactivate or delete your
        account and information from our active databases. However, we may retain some
        information in our files to prevent fraud, troubleshoot problems, assist with any
        investigations, enforce our legal terms and/or comply with applicable legal
        requirements.<br />
        If you have questions or comments about your privacy rights, you may email us at
        info@mytreat.health.
      </p>
      <p>
        10. CONTROLS FOR DO-NOT-TRACK FEATURES
        Most web browsers and some mobile operating systems and mobile applications
        include a Do-Not-Track ("ONT") feature or setting you can activate to signal your
        privacy preference not to have data about your online browsing activities monitored
        and collected. At this stage no uniform technology standard for recognizing and
        implementing ONT signals has been finalized. As such, we do not currently respond
        to ONT browser signals or any other mechanism that automatically communicates
        your choice not to be tracked online. If a standard for online tracking is adopted that
        we must follow in the future, we will inform you about that practice in a revised
        version of this privacy notice.
      </p>
      <p>
        11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        California Civil Code Section 1798.83, also known as the "Shine The Light" law,
        permits our users who are California residents to request and obtain from us, once a
        year and free of charge, information about categories of personal information (if any)
        we disclosed to third parties for direct marketing purposes and the names and
        addresses of all third parties with which we shared personal information in the
        immediately preceding calendar year. If you are a California resident and would like
        to make such a request, please submit your request in writing to us using the contact
        information provided below.
        If you are under 18 years of age, reside in California, and have a registered account
        with Services, you have the right to request removal of unwanted data that you
        publicly post on the Services. To request removal of such data, please contact us
        using the contact information provided below and include the email address
        associated with your account and a statement that you reside in California. We will
        make sure the data is not publicly displayed on the Services, but please be aware that
        the data may not be completely or comprehensively removed from all our systems
        (e.g., backups, etc.).
        CCPA Privacy Notice
        The California Code of Regulations defines a "resident" as:
        (1) every individual who is in the State of California for other than a temporary or
        transitory purpose and
        (2) every individual who is domiciled in the State of California who is outside the
        State of California for a temporary or transitory purpose
        All other individuals are defined as "non-residents."
        If this definition of “resident” applies to you, we must adhere to certain rights and
        obligations regarding your personal information.
        How do we use and share your personal information?
        More information about our data collection and sharing practices can be found in
        this privacy notice.
        You may contact us by email at info@mytreat.health, or by referring to the contact
        details at the bottom of this document.
        If you are using an authorized agent to exercise your right to opt out we may deny a
        request if the authorized agent does not submit proof that they have been validly
        authorized to act on your behalf.
        Will your information be shared with anyone else?
        We may disclose your personal information with our service providers pursuant to a
        written contract between us and each service provider. Each service provider is a
        for-profit entity that processes the information on our behalf, following the same
        strict privacy protection obligations mandated by the CCPA.
        We may use your personal information for our own business purposes, such as for
        undertaking internal research for technological development and demonstration.
        This is not considered to be "selling" of your personal information.
        Mia Wellness and Healthcare International LLC has not sold or shared any personal
        information to third parties for a business or commercial purpose in the preceding
        twelve (12) months. Mia Wellness and Healthcare International LLC has disclosed
        the following categories of personal information to third parties for a business or
        commercial purpose in the preceding twelve (12) months:
        The categories of third parties to whom we disclosed personal information for a
        business or commercial purpose can be found under "WHEN AND WITH WHOM
        DO WE SHARE YOUR PERSONAL INFORMATION?".
        Your rights with respect to your personal data
        Right to request deletion of the data - Request to delete
        You can ask for the deletion of your personal information. If you ask us to delete
        your personal information, we will respect your request and delete your personal
        information, subject to certain exceptions provided by law, such as (but not limited
        to) the exercise by another consumer of his or her right to free speech , our
        compliance requirements resulting from a legal obligation, or any processing that
        may be required to protect against illegal activities.
        Right to be informed - Request to know<br />
        Depending on the circumstances, you have a right to know:<br />
        ■ whether we collect and use your personal information;<br />
        ■ the categories of personal information that we collect;<br />
        ■ the purposes for which the collected personal information is used;<br />
        ■ whether we sell or share personal information to third parties;<br />
        ■ the categories of personal information that we sold, shared, or disclosed for a
        business purpose;<br />
        ■ the categories of third parties to whom the personal information was sold , shared,
        or disclosed for a business purpose ;<br />
        ■ the business or commercial purpose for collecting , selling , or sharing personal
        information; and<br />
        ■ the specific pieces of personal information we collected about you.
        In accordance with applicable law, we are not obligated to provide or delete
        consumer information that is de-identified in response to a consumer request or to
        reidentify individual data to verify a consumer request.
        Right to Non-Discrimination for the Exercise of a Consumer 's Privacy Rights
        We will not discriminate against you if you exercise your privacy rights.<br />
        Right to Limit Use and Disclosure of Sensitive Personal Information<br />
        We do not process consumer's sensitive personal information.<br />
        Verification Process<br />
        Upon receiving your request, we will need to verify your identity to determine you
        are the same person about whom we have the information in our system. These
        verification efforts require us to ask you to provide information so that we can match
        it with information you have previously provided us. For instance , depending on the
        type of request you submit, we may ask you to provide certain information so that
        we can match the information you provide with the information we already have on
        file, or we may contact you through a communication method (e.g., phone or email)
        that you have previously provided to us. We may also use other verification methods
        as the circumstances dictate.<br />
        We will only use personal information provided in your request to verify your
        identity or authority to make the request. To the extent possible, we will avoid
        requesting additional information from you for the purposes of verification.
        However, if we cannot verify your identity from the information already maintained
        by us, we may request that you provide additional information for the purposes of
        verifying your identity and for security or fraud-prevention purposes. We will delete
        such additionally provided information as soon as we finish verifying you.
        Other Privacy rights<br />
        ■ You may object to the processing of your personal information.<br />
        ■ You may request correction of your personal data if it is incorrect or no longer
        relevant or ask to restrict the processing of the information.<br />
        ■ You can designate an authorized agent to make a request under the CCPA on your
        behalf. We may deny a request from an authorized agent that does not submit proof
        that they have been validly authorized to act on your behalf in accordance with the
        CCPA.<br />
        ■ You may request to opt out from future selling or sharing of your personal
        information to third parties. Upon receiving an opt-out request, we will act upon the
        request as soon as feasibly possible, but no later than fifteen (15) days from the date
        of the request submission.<br />
        To exercise these rights, you can contact us by email at info@mytreat.health, or by
        referring to the contact details at the bottom of this document. If you have a
        complaint about how we handle your data, we would like to hear from you.
      </p>
      <p>
        12. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        Virginia CDPA Privacy Notice
        Under the Virginia Consumer Data Protection Act (COPA):
        "Consumer" means a natural person who is a resident of the Commonwealth acting
        only in an individual or household context. It does not include a natural person acting
        in a commercial or employment context.
        "Personal data" means any information that is linked or reasonably linkable to an
        identified or identifiable natural person. "Personal data" does not include
        deidentified data or publicly available information.
        "Sale of personal data" means the exchange of personal data for monetary
        consideration. If this definition "consumer" applies to you, we must adhere to certain
        rights and obligations regarding your personal data.
        The information we collect, use, and disclose about you will vary depending on how
        you interact with Mia Wellness and Healthcare International LLC and our Services.
        To find out more, please review the following sections in this Privacy Policy:<br />
        ■ Personal data we collect<br />
        ■ How we use your personal data<br />
        ■ When and with whom we share your personal data<br />
        Your rights with respect to your personal data<br />
        ■ Right to be informed whether or not we are processing your personal data<br />
        ■ Right to access your personal data<br />
        ■ Right to correct inaccuracies in your personal data<br />
        ■ Right to request deletion of your personal data<br />
        ■ Right to obtain a copy of the personal data you previously shared with us<br />
        ■ Right to opt out of the processing of your personal data if it is used for targeted<br />
        advertising, the sale of personal data, or profiling in furtherance of decisions that
        produce legal or similarly significant effects ("profiling")<br />
        Mia Wellness and Healthcare International LLC has not sold any personal data to
        third parties for business or commercial purposes. Mia Wellness and Healthcare
        International LLC will not sell personal data in the future belonging to website
        visitors, users, and other consumers.<br />
        Exercise Your rights Provided under the Virginia COPA
        More information about our data collection and sharing practices can be found in
        this privacy notice.<br />
        You may contact us by email at info@mytreat.health, by visiting our data subject
        or by referring to the contact details at the bottom of this document.
        If you are using an authorized agent to exercise your rights, we may deny a request
        if the authorized agent does not submit proof that they have been validly authorized
        to act on your behalf.<br />
        Verification Process<br />
        We may request that you provide additional information reasonably necessary to
        verify you and your consumer's request. If you submit the request through an
        authorized agent, we may need to collect additional information to verify your
        identity before processing your request.<br />
        Upon receiving your request, we will respond without undue delay, but in all cases,
        within forty-five (45) days of receipt. The response period may be extended once by
        forty-five (45) additional days when reasonably necessary. We will inform you of
        any such extension within the initial 45-day response period, together with the
        reason for the extension.<br />
        Right to Appeal<br />
        If we decline to take action regarding your request, we will inform you of our
        decision and reasoning behind it. If you wish to appeal our decision, please email us
        at info@mytreat.health. Within sixty (60) days of receipt of an appeal, we will
        inform you in writing of any action taken or not taken in response to the appeal,
        including a written explanation of the reasons for the decisions. If your appeal if
        denied, you may contact the Attorney General to submit a complaint.<br />
      </p>
      <p>
        13. DO WE MAKE UPDATES TO THIS NOTICE?<br />
        We may update this privacy notice from time to time. The updated version will be
        indicated by an updated "Revised" date and the updated version will be effective as
        soon as it is accessible. If we make material changes to this privacy notice, we may
        notify you either by prominently posting a notice of such changes or by directly
        sending you a notification. We encourage you to review this privacy notice
        frequently to be informed of how we are protecting your information.
      </p>
      <p>
        14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
        If you have questions or comments about this notice, you may email us at
        info@mytreat.health or by post to:
        Mia Wellness and Healthcare International LLC
        2500 Parkview Drive
        Suite 1219
        Hallandale, Florida 33009
        United States
      </p>
      <p>
        15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE
        COLLECT FROM YOU?
        Based on the applicable laws of your country, you may have the right to request
        access to the personal information we collect from you, change that information, or
        delete it. To request to review, update, or delete your personal information, please
        submit a request to info@mytreat.health.
    </div>
  </div>
</div>