import { style } from "@angular/animations";
import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-modal-contact-form",
  template: `
    <app-landing-contactus-form
      [formTipo]="formTipo"
    ></app-landing-contactus-form>
  `,
})
export class ContactFormModal implements OnInit {
  @Input() formTipo!: string;

  ngOnInit(): void {
    console.log(this.formTipo)
  }
}
