<div class="appDetailsContent">
  <div class="breadcrumb">
    <!-- appCats -->
    <div class="container">
      <a [routerLink]="'/'" i18n="Home">Home</a>
      <span> / </span> <span i18n="Manage">Manage</span> <span> / </span> <span
        i18n="PaymentsRouterLink">Payments</span>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-12 mt-5 mb-4">
      <h1 class="mat-typography__underscore" i18n="PaymentsTitlePayments">Payments</h1>
    </div>
  </div>
  <div class="container">
    <div class="filas">
      <div class="col-sm">
        <div class="filtro">
          <mat-label class="descCampo" i18n="PaymentType | Filtro Tipo en Pagos">Type</mat-label>
          <mat-select class="campo" [(ngModel)]="tipo">
            <mat-option value="TODOS"><span i18n="AllOptionType | Opcion Todos filtro tipo en pagos">ALL</span>
            </mat-option>
            <mat-option value="FACTURA"><span i18n="InvoiceOption | Opcion Facturas filtro tipo en pagos">INVOICE</span>
            </mat-option>
            <mat-option value="ORDEN"><span i18n="OrderOption | Opcion Orden filtro tipo en pagos">ORDER</span>
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="col-sm">
        <div class="filtro">
          <mat-label class="descCampo" i18n="PaymentStatus | Filtro Estado en Pagos">Status</mat-label>
          <mat-select class="campo" [(ngModel)]="estado">
            <mat-option value="TODOS"><span i18n="AllOptionStatus | Opcion Todos filtro estado en pagos">ALL</span>
            </mat-option>
            <mat-option value="PENDIENTE"><span
                i18n="PendingStatus | Opcion Pendiente filtro estado en pagos">PENDING</span></mat-option>
            <mat-option value="PAGADO"><span i18n="PayedStatus | Opcion Pagado filtro estado en pagos">PAYED</span>
            </mat-option>
            <mat-option value="EN_PROCESO"><span i18n="InProcessStatus | Opcion EnProceso filtro estado en pagos">IN
                PROCESS</span></mat-option>
            <mat-option value="NO_AUTORIZADO"><span
                i18n="NoAuthorizedStatus | Opcion NoAutorizado filtro estado en pagos">NO AUTHORIZED</span></mat-option>
            <mat-option value="ERROR"><span i18n="ErrorStatus | Opcion Error filtro estado en pagos">ERROR</span>
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="col-sm">
        <div class="filtro">
          <mat-label class="descCampo" i18n="CreationDateFilters | Filtro FechasCreacion en Pagos">Creation Date
          </mat-label>
        </div>
      </div>
      <div class="col-sm">
        <div class="filtro">
          <input matInput [matDatepicker]="picker" placeholder="From" i18n-placeholder="PlaceholderFromDatePayments"
            [(ngModel)]="fechaInicio">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>

        </div>
      </div>
      <div class="col-sm">
        <div class="filtro">

          <input matInput [matDatepicker]="picker1" placeholder="To" i18n-placeholder="PlaceholderToDatePayments"
            [(ngModel)]="fechaFin">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </div>
      </div>
      <div class="col-sm">
        <div class="botonBusqueda">

          <mat-card-content>
            <button mat-flat-button color="accent" (click)="buscar()"><span
                i18n="SearchButtonPayments | Boton Buscar en Pagos">Search</span></button>
          </mat-card-content>
        </div>

      </div>

    </div>

  </div>

  <!-- begin Payments Table -->
  <table mat-table [dataSource]="dataSource" matSort matSortActive="date" class="mat-table--numbers mat-elevation-0">
    <ng-container matColumnDef="paymentNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header><span
          i18n="NroPaymentPayments | NroPago columna en pagos">N° Payment</span></th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <ng-container matColumnDef="reference">
      <th mat-header-cell *matHeaderCellDef mat-sort-header><span
          i18n="ReferencePayments | Referencia columna en pagos">Reference</span></th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.tipoReferencia=='ORDEN' "><span i18n="Order | TipoPagoOrden Payments">Order</span></span>
        <span *ngIf="element.tipoReferencia=='FACTURA' "><span i18n="Invoice | TipoPagoFactura Payments">Invoice</span></span>
        {{(element.tipoReferencia=='ORDEN'?element.idExternoOrden:element.id)}}
      </td>
    </ng-container>

    <ng-container matColumnDef="dateCreated">
      <th mat-header-cell *matHeaderCellDef mat-sort-header><span
          i18n="CreationDatePayments | FechaCreacion columna en pagos">Creation Date</span></th>
      <td mat-cell *matCellDef="let element"> {{element.fechaCreado | date}} </td>
    </ng-container>

    <ng-container matColumnDef="datePayment">
      <th mat-header-cell *matHeaderCellDef mat-sort-header><span
          i18n="PaymentDatePayments | FechaPago columna en pagos">Payment Date</span></th>
      <td mat-cell *matCellDef="let element"> {{element.fechaPagado | date}} </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header><span
          i18n="StatusPayments | Estado columna en pagos">Status</span></th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.estadoPago=='PENDIENTE' "><span i18n="Pending | EstadoPagoPendiente Payments">Pending</span></span>
        <span *ngIf="element.estadoPago=='EN_PROCESO' "><span i18n="In_Process | EstadoPagoEnProceso Payments">In Process</span></span>
        <span *ngIf="element.estadoPago=='PAGADO' "><span i18n="Payed | EstadoPagoPagado Payments">Payed</span></span>
        <span *ngIf="element.estadoPago=='NO_AUTORIZADO' "><span i18n="No_Authorized | EstadoPagoNoAutorizado Payments">No Authorized</span></span>
        <span *ngIf="element.estadoPago=='ERROR' "><span i18n="Error | EstadoPagoError Payments">Error</span></span>
      </td>
    </ng-container>

    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef mat-sort-header><span
          i18n="ValuePayments | Valor columna en pagos">Value</span></th>
      <td mat-cell *matCellDef="let element" class="mat-cell--value"> {{element.importe}} {{element?.moneda.codigo}}
      </td>
    </ng-container>

    <ng-container matColumnDef="gateway">
      <th mat-header-cell *matHeaderCellDef mat-sort-header><span
          i18n="GatewayPayments | Pasarela columna en pagos">Gateway</span></th>
      <td mat-cell *matCellDef="let element"> {{element.pasarelaPago.nombre}} </td>
    </ng-container>

    <ng-container matColumnDef="authorization">
      <th mat-header-cell *matHeaderCellDef mat-sort-header><span
          i18n="AuthorizationPayments | Pasarela columna en pagos">Authorization</span></th>
      <td mat-cell *matCellDef="let element"> {{element.codigoAutorizacion}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [length]="total" (page)="paginar($event)" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons>
  </mat-paginator>
</div>
