import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-accept-modifications',
  templateUrl: './accept-modifications.component.html',
  styleUrls: ['./accept-modifications.component.scss']
})
export class AcceptModificationsComponent implements OnInit {

  constructor(  public dialogRef: MatDialogRef<AcceptModificationsComponent> ) { }

  ngOnInit(): void {
  }

  onUnderstandClick(): void {
    this.dialogRef.close();
  }

}
