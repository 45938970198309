import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-banner-aplicacion",
  templateUrl: "./banner-aplicacion.component.html",
  styleUrls: ["./banner-aplicacion.component.scss"],
})
export class BannerAplicacionComponent implements OnInit {

  @Input() element: any;

  constructor(route: ActivatedRoute) {}

  ngOnInit(): void {
  }
}
