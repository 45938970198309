import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { AbstractPage } from "../abstract.page";
import { environment } from "src/environments/environment";
import { SearchBoxService } from "../../services/search-box.service";
import { PlatformLocation } from "@angular/common";
import { LangService } from "src/app/services/lang.service";

@Component({
  selector: "app-about-us",
  templateUrl: "./about-us.page.html",
  styleUrls: ["./about-us.page.scss"],
  changeDetection: ChangeDetectionStrategy.Default

})
export class AboutUsPage extends AbstractPage implements OnInit {
  // IsEnglish = false;
  isEnglish = false;

  constructor(
    private searchBoxService: SearchBoxService,
    private platformLocation: PlatformLocation,
    private langService: LangService
  ) {
    super();
    this.searchBoxService.showSearchBox();
  }

  public pageTitle(): string {
    return environment.title;
  }

  ngOnInit(): void {
    this.isEnglish = this.langService.getLang().toLowerCase().trim() === "en";
    console.log(this.isEnglish)

  }

  changeLanguage(language: string) {
    window.location.href = environment.urlApp + language;
  }
}
