import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Busqueda} from '../models/assets/busqueda.model';
import {TipoFiltro} from '../models/assets/tipoFiltro.type';
import {TipoOrden} from '../models/assets/tipoOrden.type';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  public busqueda$: Subject<Busqueda>;
  public categoryOrder$: Subject<Busqueda>;
  public params: Busqueda;
  public categoryOrder: Busqueda;

  constructor() {
    this.busqueda$ = new BehaviorSubject<Busqueda>({ordenadoPor: 'ALFABETICO', tipoOrden: 'ASC'});
    this.categoryOrder$ = new BehaviorSubject<Busqueda>({ordenadoPor: 'ALFABETICO', tipoOrden: 'ASC'});

    // tslint:disable-next-line:new-parens
    this.params =  new class implements Busqueda {
      ordenadoPor: TipoFiltro;
      tipoOrden: TipoOrden;
    };
    // tslint:disable-next-line:new-parens
    this.categoryOrder = new class implements Busqueda {
      ordenadoPor: TipoFiltro;
      tipoOrden: TipoOrden;
    };
  }

  changeValue(orden: string, tipo: string){
    this.params.tipoOrden = tipo;
    this.params.ordenadoPor = orden;
    this.busqueda$.next(this.params);
  }
  changeOrderCategory(orden: string, tipo: string) {
    this.categoryOrder.tipoOrden = tipo;
    this.categoryOrder.ordenadoPor = orden;
    this.categoryOrder$.next(this.categoryOrder);
  }

  getValue(): Busqueda{
    return this.params;
  }

  getCategoryValue(): Busqueda {
    return this.categoryOrder;
  }
}
