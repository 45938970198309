import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ContactFormService {

  constructor(private httpClient: HttpClient) {

  }

  public send(params) {
    return this.httpClient.post(environment.apiBase + '/tiendas/' + environment.idStore + '/contacto', params);
  }
}
