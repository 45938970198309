import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Plan} from '../models/plan.model';
import {Orden} from '../models/orden.model';
import {PlanRecurso} from '../models/planRecurso.model';
import {OrdenPlanCompraRecurso} from '../models/ordenplancomprarecurso.model';
import {LangService} from './lang.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private httpClient: HttpClient, private langService: LangService) {
  }

  createOrderPlan(p: Plan, cupon: string, cli, con, resources): Observable<any> {
    const planOrder = {plan: p, codigoPromocion: cupon, cliente: cli, contacto: con, recursos: resources};
    return this.httpClient.post(
      environment.apiBase + '/tiendas/' + environment.idStore + '/ordenes/planes/compras',
      planOrder
    );
  }

  createOrderPlanPagoAnticipado(p: Plan, cupon: string, cli, con, resources): Observable<any> {
    const planOrder = {plan: p, codigoPromocion: cupon, cliente: cli, contacto: con, recursos: resources};
    return this.httpClient.post(
      environment.apiBase + '/tiendas/' + environment.idStore + '/ordenes/planes/compras-pago-anticipado',
      planOrder, {responseType: 'text'}
    );
  }

  getProductSubscriptions$(): Observable<Orden[]> {
    let params = new HttpParams();
    params = params.append('lang', this.langService.getLang());

    return this.httpClient.get<Orden[]>(environment.apiBase + '/tiendas/' + environment.idStore + '/ordenes/usuario',
      {params}
    );
  }

  createOrdenPlanCancel(contrato: number): Observable<any> {
    const cancelOrder = {idContrato: contrato};
    return this.httpClient.post(
      environment.apiBase + '/tiendas/' + environment.idStore + '/ordenes/planes/cancelaciones',
      cancelOrder, {responseType: 'text'}
    );
  }

  createAddOnCancelOrder(contratoId: number): Observable<any> {
    const cancelOrder = {idContrato: contratoId};
    return this.httpClient.post(
      environment.apiBase + '/tiendas/' + environment.idStore + '/ordenes/planes/cancelacion',
      cancelOrder
    );
  }

  createAddOnPurchaseOrder(planRecurso: OrdenPlanCompraRecurso[], cupon: string, contratoId: number, cli: number, con: number): Observable<any> {
    const AddOnOrder = {recursos: planRecurso, codigoPromocion: cupon, cliente: {id: cli}, contrato: {id: contratoId}, contacto: {id: con}};
    return this.httpClient.post(
      environment.apiBase + '/tiendas/' + environment.idStore + '/ordenes/complementos/compra',
      AddOnOrder, {responseType: 'text'}
    );
  }

  transitionByAction(accion: string, idOrden: number) {
    return this.httpClient.post(
      environment.apiBase + '/tiendas/' + environment.idStore + '/ordenes/planes/compras/' + idOrden + '/historias/accion/' + accion, {});
  }

  getOneExternalId(idExterno: string): Observable<Orden> {
    return this.httpClient.get<Orden>(environment.apiBase + '/tiendas/' + environment.idStore + '/ordenes/planes/compras/idExterno/' + idExterno);
  }

  createOrdenComplementCancel(contratoId, complementoId: number): Observable<any> {
    const cancelOrder = {contrato: {id: contratoId}, recurso: {id: complementoId}};
    return this.httpClient.post(
      environment.apiBase + '/tiendas/' + environment.idStore + '/ordenes/complementos/cancelacion',
      cancelOrder,
      {responseType: 'text'}
    );
  }
}
