import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-pharmacare-landing-footer",
  template: `
    <footer class="landing-footer">
      <h2><span>¡Adquiere</span> tu tarjeta digital!</h2>
      <p class="landing-footer__desc">
      Viaja tranquilo a Estados Unidos. Recibirás tu <span>tarjeta digital</span> de forma instantánea en tu celular, una vez confirmada la compra.</p>
      <button class="button-animated"><a target="_blank" [href]="buyLink">Comprar</a></button>
    </footer>
  `,
  styleUrls: ["./footer.component.scss"],
})
export class PharmacareLandingFooterComponent implements OnInit {

  @Input() buyLink: string;
  constructor() {}

  ngOnInit(): void {}
}
