import { AfterViewInit, Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import ScrollReveal from "scrollreveal";
import { AbstractPage } from "src/app/pages/abstract.page";
import { ContactFormService } from "src/app/services/contactForm.service";

@Component({
  selector: "app-total-body-scan-landing",
  templateUrl: "./total-body-scan.landing.html",
  styleUrls: ["./total-body-scan.landing.scss"],
})
export class TotalBodyScanLandingPage
  extends AbstractPage
  implements OnInit, AfterViewInit
{
  loadingForm = false;
  submitted = false;
  formErrors = "";
  showMoreInfo = false;
  squareLink = "https://square.link/u/vr769yh2";

  constructor() {
    super();
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const itemNumber = entry.target.getAttribute("data-item");
          const indicator = document.querySelector(
            `span[data-item='${itemNumber}']`
          );
          if (entry.isIntersecting) {
            indicator.classList.add("in-view");
          } else {
            indicator.classList.remove("in-view");
          }
        });
      },
      {
        threshold: 0.65, // Adjust the threshold as needed
      }
    );

    document.querySelectorAll("article[data-item]").forEach((element) => {
      observer.observe(element);
    });

    console.log("initializing scroll reveal");
    const sr = ScrollReveal();

    for (let i = 0; i < 6; i++) {
      sr.reveal(`.bottom-reveal:nth-child(${i + 1}) `, {
        duration: 500,
        origin: "bottom",
        distance: "75px",
        easing: "cubic-bezier(0.175, 0.885, 0.32, 1.275)",
        reset: false,
        mobile: false,
        delay: i * 200,
        viewOffset: {
          bottom: 100,
        },
      });
    }

    sr.reveal(`.left-reveal`, {
      duration: 500,
      origin: "left",
      distance: "75px",
      easing: "cubic-bezier(0.175, 0.885, 0.32, 1.275)",
      reset: false,
      delay: 200,
      mobile: false,
      viewOffset: {
        bottom: 100,
      },
    });

    sr.reveal(`.right-reveal`, {
      duration: 500,
      origin: "right",
      distance: "175px",
      easing: "cubic-bezier(0.175, 0.885, 0.32, 1.275)",
      reset: false,
      delay: 200,
      mobile: false,
      viewOffset: {
        bottom: 100,
      },
    });
  }

  pageTitle(): string {
    return "MyTreat - Total Body Scan";
  }

  scrollTo(id: string) {
    document.getElementById(id).scrollIntoView({
      behavior: "smooth",
      block: id === "benefits" ? "start" : "center",
    });
  }

  toggleShowMore() {
    this.showMoreInfo = !this.showMoreInfo;
  }
}
