import { Component, Input, OnInit } from '@angular/core';
import { Category } from 'src/app/models/category.model';

@Component({
  selector: 'app-banner-imagen',
  templateUrl: './banner-imagen.component.html',
  styleUrls: ['./banner-imagen.component.scss']
})
export class BannerImagenComponent implements OnInit {

  @Input() element:any;

  constructor() { }

  ngOnInit(): void {
  }

}
