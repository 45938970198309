<h2 mat-dialog-title class="mat-typography__underscore" i18n="TitleDeleteUserPopup | DeleteUser">Delete User</h2>
<mat-dialog-content>
  <p i18n="MessageDeleteUserPopup | Mensaje de borrado de usuario">You are about to delete a user.</p>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button (click)="this.closeDialog()" mat-stroked-button color="accent" mat-dialog-close i18n="ButtonCloseUserPopup | Botón de cerrar">Close</button>
  <button (click)="this.deleteUserInvitation()" mat-flat-button color="primary" i18n="Accept|boton de aceptar">
    Accept
    <mat-icon *ngIf="busy">
      <mat-spinner color="accent" diameter="20">
      </mat-spinner>
    </mat-icon>
  </button>
</mat-dialog-actions>
