import { InvoicesService } from './../../services/invoices.service';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})


export class InvoicesResolver implements Resolve<any[]> {

  constructor(private invoicesService: InvoicesService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any[]> | Promise<any[]> | any[] {
    return this.invoicesService.getAll(route.queryParams.limite, route.queryParams.desplazamiento,route.queryParams.fechaInicio, route.queryParams.fechafin, route.queryParams.rangoDias, route.queryParams.estado);
  }


}
