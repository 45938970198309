import { AfterViewInit, Component, OnInit } from "@angular/core";
import { AbstractPage } from "src/app/pages/abstract.page";
import ScrollReveal from "scrollreveal";

@Component({
  selector: "app-landing-pharmacare",
  templateUrl: "./pharmacare.landing.html",
  styleUrls: ["./pharmacare.landing.scss"],
})
export class PharmacareCardLandingPage
  extends AbstractPage
  implements OnInit, AfterViewInit
{
  constructor() {
    super();
  }

  squareLink: string = 'https://square.link/u/nZaoj86f';

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    const sr = ScrollReveal();

    sr.reveal(`.left-reveal`, {
      duration: 500,
      origin: "left",
      distance: "75px",
      easing: "cubic-bezier(0.175, 0.885, 0.32, 1.275)",
      reset: false,
      delay: 200,
      mobile: false,
      viewOffset: {
        bottom: 100,
      },
    });

    sr.reveal(`.right-reveal`, {
      duration: 500,
      origin: "right",
      distance: "175px",
      easing: "cubic-bezier(0.175, 0.885, 0.32, 1.275)",
      reset: false,
      delay: 200,
      mobile: false,
      viewOffset: {
        bottom: 100,
      },
    });

    sr.reveal(`.bottom-reveal`, {
      duration: 500,
      origin: "bottom",
      distance: "75px",
      easing: "cubic-bezier(0.175, 0.885, 0.32, 1.275)",
      reset: false,
      mobile: false,
      delay: 200,
      viewOffset: {
        bottom: 100,
      },
    });
  }

  scrollTo(id: string) {
    document.getElementById(id).scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }

  public pageTitle(): string {
    return "MyTreat - Pharmacare Card";
  }
}
