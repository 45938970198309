import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-banner-video',
  templateUrl: './banner-video.component.html',
  styleUrls: ['./banner-video.component.scss']
})
export class BannerVideoComponent implements OnInit {

  @Input() element:any;
  constructor() { }

  ngOnInit(): void {
  }

}
