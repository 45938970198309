import { PlanRecurso } from './../models/planRecurso.model';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PlanRecursoService {

  constructor(private httpClient: HttpClient) {
  }

  getAllWithAgendas(idsRecursos: number[], planId: number, fechaHoraDesde: Date): Observable<PlanRecurso[]> {

    let fechaHora = formatDate( (fechaHoraDesde!=null?fechaHoraDesde:Date.now()) , 'yyyyMMdd','en-US');

    return this.httpClient.get<PlanRecurso[]>(environment.apiBase +
    '/tiendas/' + environment.idStore + '/planes/' + planId +
    '/recursos/' + idsRecursos.join('-') +
    '/agendas?fechaHoraDesde=' + fechaHora + 'T0000-03:00');
  }
}
