import { ActivatedRoute, Router } from '@angular/router';
import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {MY_FORMATS} from '@utils';
import { SelectionModel } from '@angular/cdk/collections';

// begin HTML Demo
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { InvoicesService } from 'src/app/services/invoices.service';
import { DatePipe } from '@angular/common';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';


export interface InvoiceData {
  id: number;
  fechaFactura: Date;
  estado: string;
  importeTotal: number;
}


@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.page.html',
  styleUrls: [],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class InvoicePage implements AfterViewInit {
  data:any[]=[];

  // begin HTML Demo
  displayedColumns: string[] = ['invoiceNumber', 'date', 'status', 'value', 'acciones'];
  dataSource = new MatTableDataSource(this.data);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  // end HTML Demo

  estado:string=null;
  tipo:string=null;
  rangoDias:string=null;
  fechaInicio:Date=null;
  fechaFin:Date=null;
  busy = false;
  limite=0;
  desplazamiento=10;
  @Input() length: any;
  // @Output() lenghtChange = new EventEmitter();
  pageIndex = 0;
  pageSize = 2;
  @Output() filtroCambiado = new EventEmitter();
  selection: SelectionModel<any>;

  @Input()
  paginable = true;

  public config: MatSnackBarConfig;


  constructor(public invoicesService:InvoicesService, public  activatedRoute:ActivatedRoute, public router:Router, public datePipe: DatePipe, public snackbar: MatSnackBar) {
    this.busy=true;
    this.activatedRoute.data.subscribe(data=> {
      if(data.invoices){
        this.data=data.invoices.cfeCabezales;
        this.dataSource = new MatTableDataSource(this.data);
        this.dataSource.paginator;
        this.length=data.total;
        this.busy = false;
      }
    });
  }

  ngOnInit(): void {
  }

  // begin HTML Demo
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    //console.log("this.paginator", this.paginator)

  }
  // end HTML Demo
  buscar() {
    this.busy = true;
    var inicio:string;
    var fin:string;
    if(this.rangoDias=="NINGUNO"){
      this.rangoDias=null;
      this.fechaInicio=null;
      this.fechaFin=null;
    }
    if(this.estado=="NINGUNO"){
      this.estado=null;
    }
    if(this.rangoDias!="RANGO"){
      this.fechaInicio=null;
      this.fechaFin=null;
    }
    if (this.fechaInicio!=null) {

      inicio=this.datePipe.transform(this.fechaInicio,"yyyy-MM-ddTHH:mm:sssZ").slice(0, 10);
    }
    if (this.fechaFin!=null) {

      fin=this.datePipe.transform(this.fechaFin,"yyyy-MM-ddTHH:mm:sssZ").slice(0, 10);
    }
       this.router.navigate([],{queryParams:{estado:this.estado ,limite:this.limite , desplazamiento:this.desplazamiento,rangoDias:this.rangoDias, fechaInicio:inicio, fechafin:fin }});
  }


  algo($event: any) {
    this.pageIndex = $event.pageIndex;
    this.pageSize = $event.pageSize;
    this.filtroCambiado.emit(
      {
        pageIndex: this.pageIndex * this.pageSize,
        pageSize: this.pageSize
      }
    );
    this.obtenerInicio();
  }

  siguientePagina($event: any) {
    this.pageIndex = $event.pageIndex;
    this.pageSize = $event.pageSize;
    this.doFiltro({
      pageIndex: this.pageIndex * this.pageSize,
      pageSize: this.pageSize
    });
  }

  doFiltro($event: any) {
    this.busy = true;
    var inicio:string=null;
    var fin:string=null;
    if(this.rangoDias=="NINGUNO"){
      this.rangoDias=null;
      this.fechaInicio=null;
      this.fechaFin=null;
    }
    if(this.rangoDias!="RANGO"){
      this.fechaInicio=null;
      this.fechaFin=null;
    }
    if (this.fechaInicio!=null ) {
      inicio=this.datePipe.transform(this.fechaInicio,"yyyy-MM-ddTHH:mm:sssZ").slice(0, 10);
    }
    if (this.fechaFin!=null ) {
      fin=this.datePipe.transform(this.fechaFin,"yyyy-MM-ddTHH:mm:sssZ").slice(0, 10);
    }
    if(this.estado=="NINGUNO"){
      this.estado=null;
    }
    this.router.navigate(['.'],{queryParams:{
      desplazamiento: $event.pageIndex,
      limite: $event.pageSize,
      estado:this.estado ,
      rangoDias:this.rangoDias,
      fechaInicio:inicio,
      fechafin:fin }});
  }

  obtenerInicio(){
    this.pageIndex;
    this.pageSize;
    this.length;
  }

  pagar(invoice: any){
    this.busy = true;
    this.invoicesService.pagarFactura(invoice['id'])
      .subscribe(response => {
          this.busy = false;
          if (response != null && response !== undefined && response !== '') {
            if (response === 'EXITO') {
              //console.log("RESPONSE" , response)
              //this.wizzard.selected.completed = true;
              //this.wizzard.next();
            } else {
              document.open('text/html');
              document.write(response);
              document.close();
            }
          }
        },
        error => {
          this.busy = false;
          this.config = new MatSnackBarConfig();
          this.config.duration = 5000;
          const mensaje = 'Hubo un error al pagar la factura, Intente mas tarde';
          this.snackbar.open(mensaje, 'Cerrar', this.config);
        }
      );
  }
}
