import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-landing-header",
  template: `
    <header>
      <div class="container-tbs">
        <a href="https://www.mytreat.health/es">
          <img
            class="logo"
            src="assets/landings/tbs/images/logo.png"
            alt="logo"
          />
        </a>
        <nav>
          <ul>
            <li *ngFor="let item of navItems" (click)="navigate.emit(item.link)">{{item.name}}</li>
            <li class="button-animated">
              <a target="_self" href="https://square.link/u/vr769yh2"
                >Comprar</a
              >
            </li>
          </ul>
        </nav>
      </div>
    </header>
  `,
  styleUrls: ["./header.component.scss"],
})
export class LandingHeaderComponent implements OnInit {
  constructor() {}

  @Output() navigate: EventEmitter<any> = new EventEmitter();
  @Input() navItems: {name: string, link: string}[]
  @Input() buyLink: string;

  ngOnInit(): void {}
}
