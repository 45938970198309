<div class="container">
  <div class="row">
    <div class="col-12">
      <h2 i18n="Payment Title| El titlo de la pagina para añadir metodos de pago">Payment Methods</h2>
      <button  class="NewPaymentMethodButton" mat-raised-button color="accent">
        Add new payment method
      </button>
      <mat-card class="PayCardContainer">
        <div class="containerMatCard">
        <div class="PayementCard">
          <div>
            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRJKeCRJMsvnXUVOo8qaC4rzAf3tLrexF4DalF7aqZS7ItWeNqI&usqp=CAU"  width="100" height="100">
          </div>
          <div class="PayementCardChild">
            <a><b>••••••• 5367</b></a>
            <a>MasterCard</a>
            <a>Expiration Date: 07/22</a>
          </div>
        </div>
        <div>
          <button mat-raised-button class="EditionButton" color="accent">
            <mat-icon>delete</mat-icon>
          </button>
          <button mat-raised-button class="EditionButton" color="accent">
            <mat-icon>more_vert</mat-icon>
          </button>
        </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
