import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ContractService} from '../../../services/contract.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Contrato} from '../../../models/contrato.model';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {OrderService} from '../../../services/order.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ComplementsListComponent} from '../../../components/complements-list/complements-list.component';
import {Appraisal} from '../../../models/appraisal.model';
import {AppraisalService} from '../../../services/appraisal.service';
import {OrdenPlanCompraRecurso} from '../../../models/ordenplancomprarecurso.model';
import {Observable, of} from 'rxjs';

// begin HTML Demo
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ProfileService} from '../../../services/profile.service';
import {ContractUserService} from '../../../services/contratoUser.service';
import {ContratoUsuario} from '../../../models/contratoUsuario.model';
import { AcceptModificationsComponent } from '../../purchase/accept-modifications/accept-modifications.component';
import {Plan} from '../../../models/plan.model';
// end HTML Demo


@Component({
  selector: 'app-detail',
  templateUrl: './detail.page.html',
  styleUrls: [],
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    class: 'contentBody'
  }
})

export class DetailPage implements AfterViewInit, OnInit, OnDestroy {
  contrato: Contrato;
  tasacion: Appraisal;
  enabledCancelButton = true;
  enabledAddUserInvitationButton = true;
  disabledDeleteContractUser = false;
  dataSource: MatTableDataSource<ContratoUsuario>;

  displayedColumns: string[] = ['email', 'nombre', 'apellido', 'perfil', 'admin', 'estado', 'acciones'];
  displayedColumnsAddons: string[] = ['complemento', 'estado', 'acciones'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private contractService: ContractService,
              private route: ActivatedRoute,
              public appraisalService: AppraisalService,
              private profileService: ProfileService,
              private contratoUsuarioService: ContractUserService,
              public dialog: MatDialog) {
  }


  // begin HTML Demo
  ngAfterViewInit() {
 //   this.dataSource.paginator = this.paginator;
 //   this.dataSource.sort = this.sort;
  }

  // end HTML Demo

  ngOnInit(): void {
    this.contractService.getOne$(this.route.snapshot.params.subscriptionId).subscribe(value => {
      this.contrato = value;
      // tslint:disable-next-line:no-shadowed-variable
      this.contratoUsuarioService.obtenerTodos(this.contrato.id).subscribe(value => {
        this.dataSource = new MatTableDataSource<ContratoUsuario>(value);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
    });
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('userLoggedIn');
  }

  ngOnDestroy(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('userLoggedIn');
  }

  openPurchaseComponent(contrato: Contrato): void {
    const dialogRef = this.dialog.open(PurchaseComponentDialog, {
      width: '940px',
      height: 'auto',
      data: contrato
    });
  }

  openDialog(): void {
    this.enabledCancelButton = false;
    this.appraisalService.calculatePriceCancelacion(this.contrato.plan, this.contrato, this.contrato.cliente, 1.0, this.contrato.complementos).subscribe(value => {
      this.tasacion = value;
      this.makeRound(this.tasacion);
      const dialogRef = this.dialog.open(CancelSubscriptionDialog, {
        width: '50%',
        data: {contrato: this.contrato, tasacion: this.tasacion},
        autoFocus: false
      });
      dialogRef.afterClosed().subscribe((trash) => {
        this.enabledCancelButton = true;
      });

    });
  }

  deleteUserDialog(contratoUsuario: ContratoUsuario) {
    this.disabledDeleteContractUser = true;
    const dialogRef = this.dialog.open(DeleteUserDialog, {
      data: {contrato: this.contrato, contratoUsuario: contratoUsuario},
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe((trash) => {
      this.disabledDeleteContractUser = false;
    });
  }

  addUserInvitation() {
    this.enabledAddUserInvitationButton = false;
    this.profileService.getProfilesByProvider(this.contrato.plan.id).subscribe(value => {
      const dialogRef = this.dialog.open(AddUserDialog, {
        width: '400px',
        data: {contrato: this.contrato, perfiles: value}
      });
      dialogRef.afterClosed().subscribe((trash) => {
        this.enabledAddUserInvitationButton = true;
      });
    });

  }

  makeRound(appraisal: Appraisal) {
    appraisal.importeGravadoAnticipado = +appraisal.importeGravadoAnticipado.toFixed(1);
    appraisal.importeTotal = +appraisal.importeTotal.toFixed(1);
    // appraisal.importeImpuestos = +appraisal.importeImpuestos.toFixed(1);
    appraisal.importeAnticipado = +appraisal.importeAnticipado.toFixed(1);
    // appraisal.importeBruto = +appraisal.importeBruto.toFixed(1);
    // appraisal.importeDescuentos = +appraisal.importeDescuentos.toFixed(1);
    for (const line of appraisal.lineasCfe) {
      line.importeBruto = +line.importeBruto.toFixed(1);
      line.importeDescuentos = +line.importeDescuentos.toFixed(1);
      line.importeImpuestos = +line.importeImpuestos.toFixed(1);
      line.importeTotal = +line.importeTotal.toFixed(1);
      line.montoImponible = +line.importeTotal.toFixed(1);
      line.importeAnticipado = +line.importeAnticipado.toFixed(1);
    }
    this.tasacion = appraisal;
  }

  openDialogCancelComponent(id: number) {
    this.appraisalService.calculatePriceCancelationComponent(id).subscribe( value => {
      this.tasacion = value;
      this.makeRound(this.tasacion);
      const dialogRef = this.dialog.open(CancelComplementDialog, {
        width: '50%',
        data: {complemento: id, tasacion: value , contrato: this.contrato},
        autoFocus: false
      });
      dialogRef.afterClosed().subscribe( (trash) => {
        this.enabledCancelButton = true;
      });

    });
  }
}

@Component({
  selector: 'app-cancel-subscription-dialog',
  templateUrl: 'cancel-subscription-dialog.html',
})
export class CancelSubscriptionDialog implements OnInit {
  busy = false;
  public checkBoxFormGroup: FormGroup;
  showError = false;
  public tasacion$: Observable<Appraisal>;
  tasacion: Appraisal;

  constructor(
    public dialogRef: MatDialogRef<CancelSubscriptionDialog>,
    public orderService: OrderService,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder) {
    setTimeout(() => this.data.nativeElement.focus(), 0);

  }

  ngOnInit(): void {
    this.tasacion = this.data.tasacion;
    this.checkBoxFormGroup = this.fb.group({
      checkBox1: [null, !this.data?.contrato?.plan?.servicio?.infoTermsHtml ? Validators.nullValidator : Validators.required],
      checkBox2: [null, !this.data?.contrato?.plan?.servicio?.infoTermsHtml ? Validators.nullValidator : Validators.required]
    });
  }

  cancelSubscription() {
    if (this.checkBoxFormGroup.valid) {
      this.showError = false;
      this.busy = true;
      this.orderService.createOrdenPlanCancel(this.data.contrato.id).subscribe(response => {
        this.busy = false;
        if (response != null && response !== '') {
          if (response === 'EXITO') {
            this.dialogRef.close();
          } else {
            document.open('text/html');
            document.write(response);
            document.close();
          }
        }
        this.dialogRef.close();
      });
    } else {
      this.showError = true;
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  redireccionarATermsAndCond() {
    this.dialogRef.close();
    this.router.navigate(['/product/' + this.data?.contrato?.plan?.servicio?.idExterno], {queryParams: {tab: 3}});
  }
}

@Component({
  selector: 'app-purchase-component-dialog',
  templateUrl: './purchase-component-dialog.html',
})
export class PurchaseComponentDialog implements AfterViewInit, OnInit {
  recursos: OrdenPlanCompraRecurso[];
  cuponFormGroup: FormGroup;
  public plan$: Observable<Plan>;

  @ViewChild(ComplementsListComponent) componentsComp: ComplementsListComponent;
  rateToPay: number;
  public appraisal: Appraisal;
  busyAppraisalService = true;
  public cuponForm: FormGroup;
  checkBoxForm: FormGroup;
  busy = false;
  oneTimeCost = false;
  formDirty=true;

  constructor(
    public dialogRef: MatDialogRef<PurchaseComponentDialog>,
    @Inject(MAT_DIALOG_DATA) public contrato: Contrato,
    public orderService: OrderService,
    public appraisalService: AppraisalService,
    public fb: FormBuilder,
    public router: Router,
    public dialog: MatDialog) {
    this.plan$ = of(this.contrato.plan);
  }

  ngAfterViewInit(): void {

  }

  OpenDialogAccept() {
    const dialogRef = this.dialog.open(AcceptModificationsComponent, {
      width: '500px',
    });
  }

  fromularioCambiado(event){
       this.formDirty=true;
  }
  ngOnInit(): void {
    this.cuponFormGroup = this.fb.group(
      {
        cupon: [null, Validators.nullValidator]
      }
    );
    this.checkBoxForm = this.fb.group(
      {
        check: [false, Validators.required]
      }
    );
  }

  AcceptAndContinue() {

    if(!this.formDirty ) {
      if (this.checkBoxForm.value.check) {
        this.busy = true;
        this.orderService.createAddOnPurchaseOrder(this.recursos, '', this.contrato.id, this.contrato.cliente.id, this.contrato.contacto.id).subscribe(response => {
          this.busy = false;
          if (response != null && response !== undefined && response !== '') {
            if (response === 'EXITO') {
              this.dialogRef.close();
            } else {
              document.open('text/html');
              document.write(response);
              document.close();
            }
          }
        },
        error => {
          this.busy = false;
        });


      } else {
        this.OpenDialogAccept();
      }

    }else {
    }

  }



  closeDialog() {
    this.dialogRef.close();
  }

  callTasacion(cupon?: string) {
    this.busyAppraisalService = true;
    this.recursos = [];
    if (this.componentsComp !== undefined && this.componentsComp.recursos.length > 0) {
      if (this.componentsComp.isValid()) {
        this.recursos = this.componentsComp.recursos;
        this.appraisalService.calculatePrice(this.contrato.plan, 0, this.recursos, cupon != null ? cupon : undefined).subscribe(value => {
          this.oneTimeCost = false;
          for (const linea of value.lineasCfe) {
            if (linea.tipo_recurso === 'CONTINGENTE') {
              this.oneTimeCost = true;
              break;
            }
          }
          this.busyAppraisalService = false;
          this.appraisal = value;
          this.makeRound(this.appraisal);
          this.formDirty=false;
        });
      } else {
        this.componentsComp.showErrors = true;
      }
    }
  }


  makeRound(appraisal: Appraisal) {
    appraisal.importeGravadoAnticipado = +appraisal.importeGravadoAnticipado.toFixed(1);
    appraisal.importeTotal = +appraisal.importeTotal.toFixed(1);
    appraisal.importeImpuestos = +appraisal.importeImpuestos.toFixed(1);
    appraisal.importeAnticipado = +appraisal.importeAnticipado.toFixed(1);
    appraisal.importeBruto = +appraisal.importeBruto.toFixed(1);
    if(appraisal.importeDescuentos!=null) {
      appraisal.importeDescuentos = +appraisal.importeDescuentos.toFixed(1);
    }

    for (const line of appraisal.lineasCfe) {
      line.importeBruto = +line.importeBruto.toFixed(1);
      line.importeDescuentos = +line.importeDescuentos.toFixed(1);
      line.importeImpuestos = +line.importeImpuestos.toFixed(1);
      line.importeTotal = +line.importeTotal.toFixed(1);
      line.montoImponible = +line.importeTotal.toFixed(1);
      line.importeAnticipado = +line.importeAnticipado.toFixed(1);
    }
    this.appraisal = appraisal;
  }
}


// begin HTML Demo
@Component({
  selector: 'app-delete-user-dialog',
  templateUrl: 'delete-user-dialog.html',
})
export class DeleteUserDialog {
  busy = false;

  constructor(
    public dialogRef: MatDialogRef<DeleteUserDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private contratoService: ContractUserService) {
  }

  deleteUserInvitation() {
    this.busy = true;
    this.contratoService.eliminar(this.data.contrato.id, this.data.contratoUsuario.id).subscribe(trash => {
      this.busy = false;
      window.location.reload();
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'app-add-user-dialog',
  templateUrl: 'add-user-dialog.html',
})
export class AddUserDialog implements OnInit {
  userInvitationForm: FormGroup;
  busy = false;


  constructor(
    public dialogRef: MatDialogRef<PurchaseComponentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: FormBuilder,
    private contratoUsuarioService: ContractUserService,
    private contratoService: ContractService) {
  }

  ngOnInit(): void {
    this.userInvitationForm = this.fb.group(
      {
        nombre: [null, {validators:[Validators.required], updateOn: "change"}],
        apellido: [null, {validators:[Validators.required], updateOn: "change"}],
        email: [null, {validators:[Validators.required, Validators.email],updateOn: "change"}],
        planPerfil: [null, Validators.nullValidator],
        esAdmin: [false, Validators.required]
      }
    );
  }

  createInvitation() {
    this.userInvitationForm.markAllAsTouched();
    if (this.userInvitationForm.valid && !this.userInvitationForm?.value?.esAdmin) {
      this.busy = true;
      this.contratoUsuarioService.create(this.data.contrato.id,
        this.userInvitationForm.value.planPerfil.perfil.id,
        this.userInvitationForm.value.email,
        this.userInvitationForm.value.nombre,
        this.userInvitationForm.value.apellido).subscribe(trash => {
        this.busy = false;
        window.location.reload();
      }, error =>{
        this.busy = false;
    //    window.location.reload();
      });
    } else if (this.userInvitationForm.valid) {
      this.busy = true;
      this.contratoService.invitarAdmin(this.data.contrato.id,
        this.userInvitationForm.value.nombre,
        this.userInvitationForm.value.email,
        this.userInvitationForm.value.apellido).subscribe(trash => {
        this.busy = false;
        window.location.reload();
      }, error =>{
        this.busy = false;
  //      window.location.reload();
      });
    }
  }


  closeDialog() {
    this.dialogRef.close();
  }
}

// end HTML Demo


@Component({
  selector: 'app-cancel-complement-dialog',
  templateUrl: 'cancel-complement-dialog.html',
})
export class CancelComplementDialog implements OnInit {
  tasacion: Appraisal;
  public checkBoxFormGroup: FormGroup;
  busy = false;
  showError = false;

  constructor(
    public dialogRef: MatDialogRef<CancelComplementDialog>,
    public orderService: OrderService,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder) {
    setTimeout(() => this.data.nativeElement.focus(), 0);
  }



  ngOnInit(): void {
    this.tasacion = this.data.tasacion;
    this.checkBoxFormGroup = this.fb.group({
      checkBox1: [null, !this.data?.contrato?.plan?.servicio?.infoTermsHtml ? Validators.nullValidator : Validators.required],
      checkBox2: [null, !this.data?.contrato?.plan?.servicio?.infoTermsHtml ? Validators.nullValidator : Validators.required]
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  cancelComplement() {
    if (this.checkBoxFormGroup.valid) {
      this.showError = false;
      this.busy = true;
      this.orderService.createOrdenComplementCancel(this.data.contrato.id, this.data.complemento).subscribe(response => {
        this.busy = false;
        if (response != null && response !== ''){
          if (response === 'EXITO'){
            this.dialogRef.close();
          }
          else{
            document.open('text/html');
            document.write(response);
            document.close();
          }
        }
        this.dialogRef.close();
      });
    } else {
      this.showError = true;
    }
  }

  redireccionarATermsAndCond() {
    this.dialogRef.close();
    this.router.navigate(['/product/' + this.data?.contrato?.plan?.servicio?.idExterno], { queryParams: { tab: 3}});
  }
}
