import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {Pais} from "@models";


@Injectable({
  providedIn: 'root'
})
export class PaisService {

  constructor(private httpClient: HttpClient) {
  }

  public getAll$(): Observable<Pais[]> {
    return this.httpClient.get<Pais[]>(environment.apiBase + '/paises');
  }

  public create(pais) {
    return this.httpClient.post(environment.apiBase + '/paises', pais);
  }

  public update(id, pais) {
    return this.httpClient.put(environment.apiBase + '/paises/' + id, pais);
  }
}
