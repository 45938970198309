<h2 mat-dialog-title class="mat-typography__underscore" i18n="TitleAddUserPopup | AddNewUser">Add new user</h2>
<mat-dialog-content>
  <p class="mb-3" i18n="DescriptionAddUserPopUp">Enter user data</p>
  <form [formGroup]="userInvitationForm" class="form">
    <mat-checkbox formControlName="esAdmin" i18n="optionAdministratorUserPopup | Label de administrador">Is account admin?</mat-checkbox>
    <mat-form-field appearance="fill">
      <mat-label i18n="NameValueUserPopup | Label de nombre">Name</mat-label>
      <input formControlName="nombre" i18n-placeholder="PlaceholderUserName" matInput placeholder="Enter user name">
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label i18n="LastNameValueUserPopup | Label de apellido">Lastname</mat-label>
      <input formControlName="apellido" i18n-placeholder="PlaceholderUserLastName" matInput placeholder="Enter user lastname">
    </mat-form-field>
    <mat-form-field *ngIf="!userInvitationForm?.value?.esAdmin" appearance="fill">
      <mat-label i18n="ProfileValueUserPopup | Label de perfil">Profile</mat-label>
      <mat-select formControlName="planPerfil" i18n-placeholder="PlaceholderUserProfile" placeholder="Select a profile">
        <mat-option *ngFor="let p of data.perfiles" [value]="p">{{p.perfil.nombre}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label i18n="EmailValueUserPopup | Label de email">E-mail</mat-label>
      <input   formControlName="email" matInput i18n-placeholder="PlaceholderUserEmail" placeholder="Enter user e-mail">
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button (click)="closeDialog()" mat-stroked-button color="accent" mat-dialog-close>
    <span i18n="ButtonCloseUserPopup | Botón de cerrar">Close</span>
  </button>
  <button (click)="createInvitation()" mat-flat-button color="primary">
    <span i18n="Accept|boton de aceptar">Accept</span>
    <mat-icon *ngIf="busy">
      <mat-spinner color="accent" diameter="18">
      </mat-spinner>
    </mat-icon>
  </button>
</mat-dialog-actions>
