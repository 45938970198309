<div class="container">
  <div class="row">
    <div class="col-12 mt-4">
      <h2>Our <span class="nib">experiences</span></h2>
    </div>
  </div>
</div>

<swiper [config]="configCarousel" style="min-height: 550px;">
  <ng-template *ngFor="let product of products" class="swiper-slide" swiperSlide>

    <div class="card">
      <img src="assets/temp/{{product.idExterno}}.png" class="card-img-top" alt="{{product.nombre}}">
      <div class="card-body">
        <h3 class="card-title">{{product.nombre}}</h3>
        <p class="card-text">{{product.descripcion}}</p>
        <a href="product/{{product.idExterno}}" class="">Learn more</a>
      </div>
    </div>

  </ng-template>

</swiper>