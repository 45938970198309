<h2 mat-dialog-title class="mat-typography__underscore" i18n="BuyComplementsTitle | Compra de complementos título">Buy add-ons for {{this.contrato.plan.nombre}}</h2>
<mat-dialog-content>
  <div class="row">
    <div class="col-lg-7 mt-4">
      <mat-card class="grey-card mat-elevation-z0">
        <mat-card-title i18n="SelectComplementsTitle | Seleccionar complementos titulo">Plan Add-ons</mat-card-title>
        <mat-card-content>
          <app-complements-list  (formCambiado)="callTasacion(this.cuponFormGroup.value.cupon)" [compraComplemento]="true" [plan$]="plan$"></app-complements-list>
        </mat-card-content>
      </mat-card>

      <!-- <mat-card class="grey-card mat-elevation-z0 mt-4">
        <mat-card-content class="text-center">
          <p class="mb-3" i18n="UpgradePriceButtonExplanationComponent | Descripcion de boton actualizar Precio">After entering or updating add-ons click on this button.</p>
          <button mat-flat-button color="accent" [disabled]="this.componentsComp === undefined || this.componentsComp?.recursos?.length === 0" (click)="callTasacion(this.cuponFormGroup.value.cupon)" i18n="UpgradePriceButtonComplement | Boton actualizar precio">Update price</button>
        </mat-card-content>
      </mat-card> -->
<!--      <mat-card class="grey-card mat-elevation-z0 mt-4">-->
<!--        &lt;!&ndash; <mat-card-title i18n="DiscountCodeTitle | Titulo codigo de descuento ultimo paso del carrito">Discount Code</mat-card-title> &ndash;&gt;-->
<!--        <mat-card-content class="discount">-->
<!--          &lt;!&ndash; <p class="mb-3" i18n="DiscountCodeLabelComponentPurchase | Label de codigo de descuento en pagina de compra">Do you have a discount code? Enter it here!</p> &ndash;&gt;-->
<!--          <form [formGroup]="cuponForm" class="form">-->
<!--            <mat-form-field appearance="fill">-->
<!--              <mat-label i18n="DiscountCode | Codigo de descuento en purchase page">Do you have a discount code? Enter it here!</mat-label>-->
<!--              <input matInput type="text" formControlName="cupon">-->
<!--            </mat-form-field>-->
<!--          </form>-->
<!--          <div class="text-center mt-4">-->
<!--            <button class="mat-button&#45;&#45;block" mat-flat-button color="accent" i18n="ApplyCodeButtonPurchaseComponent | Boton Aplicar codigo de descuento" (click)="callTasacion(this.cuponFormGroup.value.cupon)">Apply</button>-->
<!--          </div>-->
<!--        </mat-card-content>-->
<!--      </mat-card>-->
    </div>

    <div class="col-lg-5 mt-4">
      <mat-card *ngIf="this.appraisal != undefined" class="grey-card summary-card mat-elevation-z0">
        <mat-card-content class="summary">
          <h3 class="mb-4" i18n="SummaryTitle | Tile summary">Summary</h3>
          <div class="summary__item" *ngIf="this.recursos.length > 0">
            <span i18n="ComplementsTitleInPurchaseComp | titulo de nuevos complementos en el resumen de orden" class="summary__category">Add-ons</span>
            <ng-container *ngFor="let linea of this.appraisal.lineasCfe">
              <div *ngIf="linea.nombreRecurso != undefined" class="summary__item-row">
              <span class="summary__name">
                <ng-container *ngIf="linea?.tipo_recurso === 'CONTINGENTE'"><span class="summary__count">{{linea.cantidad}}x</span></ng-container>
                {{linea.nombreRecurso}}
                <ng-container *ngIf="linea?.tipo_recurso === 'CONTINGENTE'">*</ng-container>
              </span>
                <span class="summary__value">
                <span>{{this.appraisal.moneda.codigo}}</span>
                <span>{{linea.importeBruto}}</span>
              </span>
              </div>
            </ng-container>
          </div>
          <div class="info my-4" *ngIf="this.oneTimeCost" i18n="OneTimeCostDisclaimer | costos unicos aclaracion">* One-time costs</div>
          <div class="summary__item summary__item--bg">
            <div class="summary__item-row">
              <span class="summary__name">Subtotal</span>
              <span class="summary__value">
              <span>{{this.appraisal.moneda.codigo}}</span>
              <span>{{this.appraisal.importeBruto}}</span>
            </span>
            </div>
          </div>
          <div class="summary__item summary__item--bg">
            <div class="summary__item-row">
              <span class="summary__name" i18n="TaxesSummary | Titulo impuestos en el resumen de orden">Taxes</span>
              <span class="summary__value">
              <span>{{this.appraisal.moneda.codigo}}</span>
              <span>{{this.appraisal.importeImpuestos}}</span>
            </span>
            </div>
          </div>
          <!-- <hr />
          <h4 class="mt-3">
            <span i18n="PreviouslyDisclaimerText | Texto que aclara que lo que prosigue son planes/complementos previamente ya comprados">Previously Hired/Buyed</span>
          </h4>
          <div class="summary__item">
            <span class="summary__category">Plan</span>
            <ng-container *ngFor="let precioPlan of this.contrato?.plan?.precios">
              <div class="summary__item-row">
                <span class="summary__name">{{this.contrato.plan.nombre}}</span>
                <span *ngIf="precioPlan.tipo === 'MENSUAL'" class="summary__value">
                  <span>{{precioPlan.moneda.codigo}}</span>
                  <span>{{precioPlan.precio.toFixed(1)}}</span>
                </span>
              </div>
            </ng-container>
          </div>
          <div *ngIf="this.contrato?.complementos?.length > 0" class="summary__item">
            <span class="summary__category" i18n="ComplementsTitle | titulo de complementos en el resumen de orden">Complements</span>
            <ng-container *ngFor="let complemento of this.contrato?.complementos">
              <div class="summary__item-row">
                <span class="summary__name">
                  <ng-container *ngIf="complemento?.planRecurso?.recurso?.tipo === 'CONTINGENTE'"><span class="summary__count">{{complemento.cantidad}}x</span></ng-container>
                  {{complemento?.planRecurso?.recurso?.nombre}} <ng-container *ngIf="complemento?.planRecurso?.recurso?.tipo === 'CONTINGENTE'">*</ng-container>
                </span>
                <span class="summary__value">
                  <span>{{complemento?.planRecurso?.precios[0]?.moneda?.codigo}}</span>
                  <span>{{complemento?.planRecurso?.precios[0]?.precio.toFixed(1)}}</span>
                </span>
              </div>
            </ng-container>
          </div> -->
          <div class="summary__item summary__item--highlighted">
            <div class="summary__item-row">
            <span class="summary__name">
              <strong i18n="PurchaseTotalComplements | Titulo Total mensual">Purchase Total</strong>
            </span>
              <span class="summary__value">
              <span><strong>{{this.appraisal.moneda.codigo}}</strong></span>
              <span><strong>{{this.appraisal.importeTotal}}</strong></span>
            </span>
            </div>
          </div>
          <div *ngIf="appraisal.importeAnticipado > 0">
            <hr/>
            <div class="summary__item summary__item--highlighted">
              <div class="summary__item-row">
              <span class="summary__name">
                <strong i18n="PrepaidTitlePurchaseComponentUpdate | Titulo pago anticipado">Amount to pay now</strong>
              </span>
                <span class="summary__value">
                <span><strong>{{this.appraisal.moneda.codigo}}</strong></span>
                <span><strong>{{this.appraisal.importeAnticipado}}</strong></span>
              </span>
              </div>
            </div>

            <p class="small mt-3" i18n="DescriptionPrepaidComplement | descripcion de pago anticipado">
              You must pay this amount online to proceed with your purchase
            </p>
          </div>
          <form [formGroup]="checkBoxForm" class="form mt-4">
            <mat-checkbox formControlName="check">
            <span i18n="AddOnsChecker | checker para aceptar la compra de los complementos">
              I agree that these charges will be applied to my plan.
            </span>
            </mat-checkbox>
          </form>
        </mat-card-content>
      </mat-card>
    </div>

  </div>

  <div class="row">
    <div class="col-lg-12 mt-4">

    </div>
  </div>
</mat-dialog-content>

<div mat-dialog-actions align="end">
  <button mat-stroked-button color="accent" (click)="this.closeDialog()" i18n="ButtonCloseUserPopup | Botón de cerrar">
    Close
  </button>
 <button mat-flat-button color="primary" i18n="Accept|boton de aceptar"  (click)="this.AcceptAndContinue()">
    Accept
    <mat-icon *ngIf="busy">
      <mat-spinner color="accent" diameter="20">
      </mat-spinner>
    </mat-icon>
  </button>
</div>
