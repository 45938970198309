import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ErrorHandlerService} from '../error-handler.service';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  constructor(private errorHandlerService: ErrorHandlerService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        }, (err: any) => {
          if (err instanceof HttpErrorResponse) {
            this.errorHandlerService.handleError(err);
          }
        }
      )
    );
  }
}
