<router-outlet *ngIf="this.isLanding" ></router-outlet>

<mat-sidenav-container *ngIf="!this.isLanding">
  <!-- MOBILE -->
  <mat-sidenav #sidenav mode="over" opened="false" position="end" class="sidenav">
    <button mat-icon-button (click)="sidenav.toggle()" class="sidenav__close">
      <mat-icon>close</mat-icon>
    </button>

    <div class="sidenav__container">
      <aside>
        <ng-container *ngIf="!user">
          <div mat-list-item>
            <button mat-button i18n="Login | Icono del header en el cual se hace Login" (click)="login()">
              <mat-icon color="primary">
                person
              </mat-icon>
            </button>
          </div>
        </ng-container>
        <button mat-button>
          <mat-icon matBadgePosition="above after" id="badgeContent" matBadgeColor="primary" matBadgeSize="small">
            notifications
          </mat-icon>
        </button>
        <button mat-button (click)="logOut()">
          <mat-icon>exit_to_app</mat-icon>
        </button>
        <!-- </ng-container> -->
        <mat-divider></mat-divider>
        <!-- <button mat-button [matMenuTriggerFor]="language">
          <span *ngIf="isEnglish">EN</span>
          <span *ngIf="!isEnglish">ES</span>
        </button> -->
        <!-- <mat-menu #language="matMenu" class="mat-menu-language">
          <a mat-button mat-icon *ngIf="isEnglish" (click)="changeLanguage('es')"><img src="assets/images/flags/ES.png" alt="" /> Español</a>
          <a mat-button mat-icon *ngIf="!isEnglish" (click)="changeLanguage('en')"><img src="assets/images/flags/UK.png" alt="" /> Inglés</a>
        </mat-menu> -->
      </aside>
      <div class="sidenav__content">
        <ul class="sidenav__menu">
          <li>
            <span (click)="toggleNavigationSub('Categories', $event)"
              i18n="CategoriesButton | Boton de menu de categorias">
              <mat-icon>menu</mat-icon>
              Categories
            </span>
            <mat-nav-list [@slideInOut]="navigationSubState.Categories" class="sidenav__submenu">
              <ng-container *ngFor="let category of categories$ | async">
                <div *ngIf="!category.ocultar" mat-list-item>
                  <a (click)="sidenav.toggle()" [routerLink]="'/'+category.fullIdExterno">{{category.nombre}}</a>
                  <ng-container *ngFor="let subcategoria of category?.categorias">
                    <div *ngIf="!subcategoria.ocultar" mat-list-item>
                      <a class="subcategoriaMobile" (click)="sidenav.toggle()"
                        [routerLink]="'/'+subcategoria.fullIdExterno">{{subcategoria.nombre}}</a>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </mat-nav-list>
          </li>
          <li>
            <span *ngIf="!user" (click)="login()">
              <mat-icon>login</mat-icon>
              Login
            </span>
          </li>
          <li *ngIf="user">
            <span (click)="toggleNavigationSub('Manage', $event)">
              <mat-icon color="primary">settings</mat-icon>
              Manage
            </span>
            <mat-nav-list [@slideInOut]="navigationSubState.Manage" class="sidenav__submenu">
              <div mat-list-item>
                <a routerLink="/subscriptions">
                  <span
                    i18n="Suscripciones Icon| Icono del menu del header en el cual se tiene acceso a las suscripciones">Subscriptions</span>
                </a>
              </div>
              <div mat-list-item>
                <a routerLink="ordenes/servicios">
                  <span
                    i18n="Ordenes de Servicio Icon| Icono del menu del header en el cual se tiene acceso a las ordenes de servicio">Service
                    orders</span>
                </a>
              </div>
              <div mat-list-item>
                <a routerLink="invoice">
                  <span
                    i18n="Facturas Icon| Icono del menu del header en el cual se tiene acceso a las facturas">Invoices</span>
                </a>
              </div>
              <div mat-list-item>
                <a routerLink="payments">
                  <span
                    i18n="Pagos Icon| Icono del menu del header en el cual se tiene acceso a los pagos">Payments</span>
                </a>
              </div>
            </mat-nav-list>
          </li>
          <li *ngIf="user">
            <span (click)="toggleNavigationSub('User', $event)">
              <mat-icon color="primary">person</mat-icon>
              User
            </span>
            <mat-nav-list [@slideInOut]="navigationSubState.User" class="sidenav__submenu">
              <div mat-list-item>
                <a href="user"
                  i18n="UserData | Icono del menu del header en  el cual se tiene acceso a los datos de usuario">
                  User data
                </a>
              </div>
            </mat-nav-list>
          </li>
        </ul>
      </div>
    </div>
  </mat-sidenav>
  <!-- MOBILE -->

  <mat-sidenav-content>
    <app-normal-layout>
      <app-header [categories]="categories$ | async" (sidenavToggle)="sidenav.toggle()" class="header"></app-header>
      <div class="content">
        <router-outlet  class="router-flex"></router-outlet>
      </div>
      <app-footer class="footer"></app-footer>
    </app-normal-layout>
    <app-wpp-btn *ngIf="wpp"></app-wpp-btn>
  </mat-sidenav-content>

</mat-sidenav-container>


