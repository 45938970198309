import { Component } from "@angular/core";

@Component({
  selector: "app-pharmacare-landing-third-section",
  template: `
    <figure class="photo-mobile">
      <img
        src="assets/landings/pharmacare/images/pharmacard-wallet.png"
        alt="Pharmacare Card Wallet"
      />
    </figure>
    <h2><span>¿Cómo</span> funciona?</h2>
    <section id="">
      <div class="grid">
        <div class="left-reveal">
          <article class="card">
            <div class="number">1</div>
            <h5>Compra tu Tarjeta Digital</h5>
            <p>
              Adquiere Pharmcare Digital Card por solo US$7 al mes, con una
              suscripción inicial básica de 6 meses, a través de nuestra
              plataforma.
            </p>
          </article>
          <article class="card">
            <div class="number">2</div>
            <h5>Recibe tu Código</h5>
            <p>
              Recibirás un código único en tu celular para empezar a usarlo
              inmediatamente en farmacias participantes en EE.UU.
            </p>
          </article>
          <article class="card">
            <div class="number">3</div>
            <h5>Valida tu Receta</h5>
            <p>
              De ser necesario, envía tu receta médica a Pharmcare Services para
              que sea validada en EE.UU., y accede fácilmente a los medicamentos
              que necesitas.
            </p>
          </article>
          <article class="card">
            <div class="number">4</div>
            <h5>Disfruta de los Descuentos</h5>
            <p>
              Usa tu código sin límite en farmacias participantes y ahorra en
              tus compras de medicamentos.
            </p>
          </article>
        </div>
        <figure class="card right-reveal">
          <img
            src="assets/landings/pharmacare/images/pharmacard-wallet.png"
            alt="Pharmacare Card Wallet"
          />
        </figure>
      </div>
    </section>
  `,
  styleUrls: ["./third-section.component.scss"],
  host: {
    class: "container-pharma",
    id: 'information'
  },
})
export class PharmacareLandingThirdSection {}
